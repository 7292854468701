import React from 'react';
import FieldContainer from './FieldContainer';
import { makeStyles } from '@material-ui/core';
import FieldTitle from './FieldTitle';
import ThumbsUpIcon from '@material-ui/icons/DoneAllRounded';
import CreateYourOwnFormButton from './CreateYourOwnFormButton';
import { useColorStyle } from './utils';
import { useFormTheme } from './theme';

interface ThankYouProps {
  message: string
}

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
  icon: {
    fontSize: '146px',
  },
});

const ThankYou: React.FC<ThankYouProps> = props => {
  const { message } = props;
  const classes = useStyles();
  const { answerColor } = useFormTheme();
  const iconColorStyle = useColorStyle(answerColor);

  return (
    <FieldContainer>
      <div className={classes.root}>
        <ThumbsUpIcon
          style={iconColorStyle}
          className={classes.icon}/>

        <FieldTitle
          title={message || 'Thanks for completing our form'}/>

        <CreateYourOwnFormButton/>
      </div>
    </FieldContainer>
  );
};

export default React.memo(ThankYou);
