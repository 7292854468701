import { LookAndFeel } from "./theme";
import { CollaboratorAccessLevel, ProgressBarType } from "src/global";
import { Workspace } from "./workspace";

export interface Filter {
  operator: string;
  value?: string | number | string[];
}

export interface Choice {
  reference?: string;
  label?: string;
}

export interface Labels {
  left?: string;
  center?: string;
  right?: string;

  [x: string]: string | undefined;
}

export interface ConditionalRule {
  type: "if";
  operator?: string;
  value?: string | number;
  complete?: boolean;
}

export const LOGICAL_AND = "and";
export const LOGICAL_OR = "or";
export type LogicalValue = typeof LOGICAL_AND | typeof LOGICAL_OR;

export type Rule = ConditionalRule;

export interface Condition {
  rules: Rule[];
  logic: LogicalValue;
  negate?: boolean;
}

export interface WithCondition {
  condition: Condition;
}

export interface Jump extends WithCondition {
  target?: string;
}

export interface Validation extends WithCondition {
  error?: string;
}

export interface Field {
  id: string;
  reference?: string;
  type: string;
  title: string;

  descriptionEnabled?: boolean;
  description?: string;

  image?: string;

  jumps: Jump[];
  defaultJumpTarget?: string;

  validations: Validation[];
  required?: boolean;

  /************* Varying properties *************/
  // Choice & dropdown
  choices?: Choice[];
  multiSelectionEnabled?: boolean;
  otherChoiceEnabled?: boolean;
  randomize?: boolean;
  alphabeticalOrder?: boolean;

  // Rating
  steps?: number;
  shape?: string;

  // opinion scale (aka, slider)
  startValue?: number;
  // endValue?: number  Uses steps instead

  // opinion scale
  labelsEnabled?: boolean;
  labels?: Labels;

  // date
  dateFormat?: string;

  // phone numbers
  defaultCountry?: string;

  // statements
  buttonText?: string;

  // Welcome
  leftAlign?: boolean;
}

export interface UniqueResponseRules {
  enabled?: boolean;
  ipAddress?: boolean;
  field?: string;
}

export interface Form {
  // READ ONLY
  id: string;
  user: string;
  accessLevel: CollaboratorAccessLevel;
  responseCount: number;
  isTemplate?: boolean;
  version: number;

  // EDITABLE AND PUBLIC
  name: string;
  workspace: string;
  customID: string;
  theme: LookAndFeel;
  fields: Field[];
  uniqueResponseRules: UniqueResponseRules;

  // SETTINGS:: EDITABLE AND REQUIRE DESIGNER ACCESS LEVEL
  isPublic: boolean;
  geoLocation?: boolean;
  progressBar: {
    enabled: boolean;
    type: ProgressBarType;
  };
  ownNotification: {
    enabled: boolean;
    email?: string;
  };
  thankYouMessage: string;

  smsMessage: string;
  responseCompletedWebhook?: string;

  createdAt: string;
  updatedAt: string;
}

export type Template = Omit<Form, "workspace"> & {
  workspace?: Workspace;
};

export type WorkspaceForm = Pick<
  Form,
  | "id"
  | "customID"
  | "user"
  | "name"
  | "theme"
  | "workspace"
  | "accessLevel"
  | "createdAt"
> & {
  responseCount: number;
};

export type LightForm = Omit<Form, "fields">;
