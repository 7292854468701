import React, { FC, memo } from 'react';
import QuoteIcon from '@material-ui/icons/FormatQuote';
import { BaseCollectorProps } from './types';
import FieldContainer from './FieldContainer';
import FieldPositionIconBlock from './FieldPositionIconBlock';
import FieldTitle from './FieldTitle';
import FieldDescription from './FieldDescription';
import BaseCollectorControls from './BaseCollectorControls';
import Spacer from '../ui/Spacer';

const iconStyle = { fontSize: 30 };

const StatementCollector: FC<BaseCollectorProps> = props => {
  const { field, position, onGoNext, onGoBack } = props;

  return (
    <FieldContainer>
      <FieldPositionIconBlock
        icon={<QuoteIcon style={iconStyle}/>}
        position={position}/>

      <FieldTitle
        title={field.title}
        required={field.required}/>

      <FieldDescription
        enabled={field.descriptionEnabled}
        text={field.description}/>

      <Spacer size={5}/>

      <BaseCollectorControls
        onNext={onGoNext}
        onPrev={onGoBack}
        buttonText={field.buttonText || 'OK'}/>
    </FieldContainer>
  );
};

export default memo(StatementCollector);
