import React, { CSSProperties } from 'react';
import Cell from '../ui/Cell';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import Grid from '../ui/Grid';
import { Labels } from '../types/form';
import { useFormTheme } from './theme';

interface OpinionScaleLabelsProps {
  enabled?: boolean
  labels?: Labels
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1)
  }
}));

const OpinionScaleLabels: React.FC<OpinionScaleLabelsProps> = props => {
  const classes = useStyles();
  const {answerColor} = useFormTheme();
  const {labels, enabled} = props;

  if (!enabled || !labels)
    return null;

  return (
    <Grid columns="1fr 1fr 1fr" style={{color: answerColor}} className={classes.root}>
      {'left|center|right'.split('|').map(key => (
        <Cell middle key={key} style={{textAlign: key} as CSSProperties}>
          <Typography noWrap variant="body2">
            {labels[key]}
          </Typography>
        </Cell>
      ))}
    </Grid>
  );
};

export default React.memo(OpinionScaleLabels);
