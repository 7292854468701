import { OptionsObject, useSnackbar, VariantType } from 'notistack';
import IconButton from '../ui/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';

type Key = OptionsObject['key'];

const snackbarCloseAction =
  (closeSnackbar: (key?: Key) => void) =>
    (key: Key) => (
      <IconButton
        icon={<ClearIcon/>}
        onClick={() => closeSnackbar(key)}/>
    );

const useNotificationMaker = (variant: VariantType) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return React.useCallback(
    (message: React.ReactNode, persist?: boolean) => {
      enqueueSnackbar(message, {
        variant: variant,
        persist: persist,
        action: persist ? snackbarCloseAction(closeSnackbar) : undefined,
      });
    }, [enqueueSnackbar, closeSnackbar, variant]);
};

export default function useNotification() {
  return {
    onShowError: useNotificationMaker('error'),
    onShowSuccess: useNotificationMaker('success'),
    onShowInfo: useNotificationMaker('info'),
  };
}
