import React from "react";
import { Choice, Field } from "./types/form";
import { LookAndFeel } from "./types/theme";
import {
  CLIENT_LOCALHOST_PORT,
  CLIENT_SUB_DOMAIN,
  DOMAIN_NAME,
  isAnswerable,
  NumberMap,
  RESPONDENT_LOCALHOST_PORT,
  RESPONDENT_SUB_DOMAIN,
  StringMap,
  toStandardDateString,
  TYPE_STATEMENT,
  WithID,
} from "src/global";
import * as colorManipulator from "@material-ui/core/styles/colorManipulator";
import axios from "axios";
import validate from "validate.js";
import moment from "moment";
import useTheme from "@material-ui/core/styles/useTheme";
import { useMediaQuery } from "@material-ui/core";
import { FieldMetas } from "./fieldMetas";
import qs from "querystring";

export type GetProps<C> = C extends React.ComponentType<infer P> ? P : any;

/********* urls ***************/
function generateOrigin(liveSubDomain: string, localhostPort: number) {
  const origin = window.location.origin;

  const hostname = origin.toLowerCase().includes(DOMAIN_NAME)
    ? `${liveSubDomain}.${DOMAIN_NAME}`
    : `localhost:${localhostPort}`;

  return `${window.location.protocol}//${hostname}`;
}

export const getClientOrigin = () =>
  generateOrigin(CLIENT_SUB_DOMAIN, CLIENT_LOCALHOST_PORT);
export const getRespondentOrigin = () =>
  generateOrigin(RESPONDENT_SUB_DOMAIN, RESPONDENT_LOCALHOST_PORT);

/****** GETTERS ***********/
export const isValidationEnabled = (type: string) =>
  !FieldMetas[type].disableValidation && isAnswerable(type);

export function getIndexById<T extends WithID>(id: any, stack: T[]): number {
  const index = stack.findIndex(({ id: needleID }) => id === needleID);
  if (index < 0) throw Error(`Could not find item by id ${id}`);
  return index;
}

export function getItemById<T extends WithID>(id: any, stack: T[]): T {
  return stack[getIndexById(id, stack)];
}

export function range(
  size?: number,
  startAt: number = 0
): ReadonlyArray<number> {
  return [...Array(size || 0).keys()].map((i) => i + startAt);
}

export const noop = () => {};

export const numberFormat = (n: number) =>
  n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const toDecimalPoints = (n: number, decimals: number) => {
  const exponent = Math.pow(10, decimals);
  return Math.round(exponent * n) / exponent;
};

export const objectSum = (o: StringMap<number> | NumberMap<number>) =>
  Object.values(o).reduce((total, v) => total + v, 0);

export function wellDefined<T>(x: T | undefined): x is T {
  return x !== undefined && !!x;
}

export function wellDefinedChoiceLabels(choices?: Choice[]): string[] {
  return (choices || []).map((x) => x.label).filter(wellDefined);
}

export function spliceCopy<T>(
  orig: T[],
  start: number,
  deleteCount: number,
  ...items: T[]
): T[] {
  const clone = orig.slice(0);
  clone.splice(start, deleteCount, ...items);
  return clone;
}

/********* colors **************/
export const isLightColor = (color: string) => {
  try {
    return (
      colorManipulator.getContrastRatio("#000", color) >
      colorManipulator.getContrastRatio("#fff", color)
    );
  } catch (e) {
    return true;
  }
};

export const foregroundColor = (backgroundColor: string) => {
  const dark = "#000";
  const light = "#fff";
  return isLightColor(backgroundColor) ? dark : light;
};

/********** dom manipulations ******************/
export const getDisplayName = (Component: React.ComponentType<any>) =>
  Component.displayName || Component.name || "Component";

export function loadFont(theme: LookAndFeel) {
  import("webfontloader").then((WebFont) => {
    WebFont.load({
      google: {
        families: [theme.fontFamily],
      },
    });
  });
}

/********** iterative position generator ********/
export const indexToLetter = (index: number) => String.fromCharCode(index + 65);

export type Position = number | string;

export const computeFieldPosition = (fields: Field[], field: Field) => {
  const answerable = isAnswerable(field);
  const index = fields
    .filter((x) => isAnswerable(x) === answerable)
    .findIndex((x) => x.id === field.id);

  if (index < 0) return undefined;

  if (answerable) return index + 1;

  return indexToLetter(index);
};

export class PositionGenerator {
  private __answerablePosition: number;
  private _statementPosition: number;

  constructor() {
    this.__answerablePosition = 0;
    this._statementPosition = -1;
  }

  next(type: string): Position | undefined {
    if (isAnswerable(type)) return ++this.__answerablePosition;
    if (type === TYPE_STATEMENT)
      return indexToLetter(++this._statementPosition);
    return undefined;
  }
}

/*********** application defaults ******************/
interface DetailedError {
  attribute: string;
  error: string;
}

export function configureDefaults() {
  axios.defaults.withCredentials = true;
  // axios.interceptors.request.use(function(config) {
  //   config.withCredentials = true;
  //   return config;
  // });

  validate.formatters.first = function (errors: DetailedError[]) {
    return errors
      .reverse()
      .reduce((e, { attribute, error }) => ({ ...e, [attribute]: error }), {});
  };

  // @ts-ignore
  validate.options = { format: "first" };

  type DateOptions = { dateOnly?: boolean };

  validate.extend(validate.validators.datetime, {
    parse: toMoment,
    format: function (value: number, options: DateOptions) {
      const format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
      return moment.utc(value).format(format);
    },
  });
}

export function useIsSmallScreen() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.only("xs"));
}

export const toMoment = (value: any) =>
  +moment.utc(toStandardDateString(value));

export const parseQueryString = (q: string) => qs.parse(q.replace(/\?+/, ""));
