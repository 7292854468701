import { LookAndFeel } from '../types/theme';
import { isLightColor } from '../helpers';
import validate from 'validate.js';
import React, { CSSProperties } from 'react';
import { AnswerState } from './types';

export const isEmpty = (answer: any) =>
  !!validate.single(answer, {presence: { allowEmpty: false }});

export const isAnswerWellFilled =
  ({ answer, error }: AnswerState) => !error && !isEmpty(answer);

export const isDarkBg = (theme: LookAndFeel) => {
  const bg = theme.backgroundImage;
  if (bg && bg.image) return !!(bg.brightness && bg.brightness < 0);
  return !isLightColor(theme.backgroundColor);
};

export const useColorStyle = (color?: string): CSSProperties =>
  React.useMemo(() => ({ color }), [color]);
