import React from 'react';
import { Dialog, Theme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import Loader from './Loader';
import { makeStyles } from '@material-ui/core/styles';

interface PopupLoadingProps {
  open: boolean
}

const paperProps = {
  style: { background: 'none' },
  elevation: 0,
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(20, 0),
    textAlign: 'center',
  },
}));

const PopupLoading: React.FC<PopupLoadingProps> = props => {
  const classes = useStyles();

  return (
    <Dialog open={props.open} PaperProps={paperProps}>
      <div className={classes.root}>
        <Loader
          type="Bars"
          color={blue[300]}
          height="40px"
          width="80px"/>
      </div>
    </Dialog>
  );
};

export default React.memo(PopupLoading);
