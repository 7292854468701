import React, { ChangeEventHandler, useCallback } from "react";
import { TextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import AnswerInput from "./AnswerInput";
import { StringMap } from "src/global";

type FieldProps = Omit<
  TextFieldProps,
  "value" | "defaultValue" | "onChange" | "variant" | "fullWidth"
>;

export default function TextLike(
  fieldProps?: FieldProps,
  constraints?: StringMap
) {
  return AnswerInput({
    defaultValue: "" as string,
    constraints,
    keys: [],
  })((props) => {
    const { onChange, value } = props;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => onChange(e.target.value),
      [onChange]
    );

    return (
      <TextField
        fullWidth
        defaultValue={value}
        onChange={handleChange}
        variant="outlined"
        placeholder="Type your answer here..."
        {...fieldProps}
      />
    );
  });
}
