import React from 'react';
import { GeoCoordinates } from '../types/response';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import LoadingSpinner from '../ui/LoadingSpinner';
import Spacer from '../ui/Spacer';
import { red } from '@material-ui/core/colors';

const STATUS_LOADING = 'loading';
const STATUS_REJECTED = 'rejected';
const STATUS_RESOLVED = 'resolved';

interface GeoLocationState {
  status: string
  position?: GeoCoordinates
  error?: string
}

interface GeoLocationProps {
  forced?: boolean
  required?: boolean
  onChange: (location?: GeoCoordinates) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 9,
    textAlign: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, .76)',
    overflowY: "auto"
  },
  container: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    margin: theme.spacing(3, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
      maxWidth: 600,
      margin: theme.spacing(10),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  error: {
    color: red[500],
    marginBottom: theme.spacing(3)
  }
}));

const GeoLocation: React.FC<GeoLocationProps> = props => {
  const classes = useStyles();
  const [state, setState] = React.useState<GeoLocationState>({
    status: STATUS_LOADING,
  });

  const { forced, required, onChange } = props;
  const { status, position } = state;

  React.useEffect(() => {
    if (status === STATUS_RESOLVED)
      onChange(position);
  }, [status, position, onChange]);

  const getPosition = React.useCallback(() => {
    if (!navigator.geolocation) {
      setState({
        status: STATUS_REJECTED,
        error: 'Geolocation is not supported',
      });
    } else {
      setState({
        status: STATUS_LOADING,
      });

      navigator.geolocation.getCurrentPosition(
        ({ coords }) =>
          setState({
            status: STATUS_RESOLVED,
            position: [coords.longitude, coords.latitude],
          }),
        (e) =>
          setState({
            status: STATUS_REJECTED,
            error: e.message,
          }),
      );
    }
  }, []);

  React.useEffect(getPosition, [getPosition]);

  if (status === STATUS_RESOLVED)
    return null;

  if (status === STATUS_REJECTED && !required)
    return null;

  if (!forced && status !== STATUS_REJECTED)
    return null;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {state.status === STATUS_LOADING && (
          <LoadingSpinner px/>
        )}
        {state.status === STATUS_REJECTED && (
          <div>
            <div className={classes.error}>
              Error: <strong>{state.error}</strong>
            </div>

            <Typography variant="h3">
              Geolocation services must be enabled.
            </Typography>

            <Spacer vertical size={6}/>
            <Typography variant="h6">
              Please update your settings to allow location sharing.
              Then click Retrieve Position button below to proceed.
            </Typography>

            <Spacer vertical size={10}/>
            <Button
              color="primary"
              variant="contained"
              onClick={getPosition}>
              Retrieve Position
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(GeoLocation);
