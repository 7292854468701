import { omit } from "src/global/objectUtils";
import Api from "../Api";

interface UniqueAnswerQuery {
  answer: any;
  formId: string;
  fieldId: string;
}

export default class AnswersResource {
  static isUniqueAnswer = (params: UniqueAnswerQuery) =>
    Api.post<boolean>(
      Api.replace("/surveys/:formId/answers/:fieldId/is-unique", params),
      omit(params, ["formId", "fieldId"])
    );
}
