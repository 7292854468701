import {
  Condition,
  ConditionalRule,
  Jump,
  LOGICAL_OR,
  UniqueResponseRules,
  Validation,
} from "../types/form";
import OPERATORS from "src/global/operators";
import { identityFn } from "src/global/arrayUtils";
import { AnswerType } from "./types";

export function uniqueRulesEnabled(
  fieldID: string,
  uniqueRules?: UniqueResponseRules
) {
  return uniqueRules && uniqueRules.enabled && uniqueRules.field === fieldID;
}

export function validateAnswer(answer: AnswerType, validations?: Validation[]) {
  if (validations)
    for (const validation of validations)
      if (parseCondition(answer, validation.condition))
        return validation.error ? validation.error : "Answer is invalid";
  return undefined;
}

export function jumpTo(
  answer: AnswerType,
  jumps?: Jump[],
  defaultJumpTarget?: string
) {
  if (jumps)
    for (const jump of jumps)
      if (parseCondition(answer, jump.condition)) return jump.target;
  return defaultJumpTarget;
}

function parseCondition(value: AnswerType, condition: Condition) {
  const { rules, logic, negate } = condition;

  if (!rules || rules.length === 0) return true;

  const results = rules.map((rule) => check(value, rule));
  const result =
    logic === LOGICAL_OR ? results.some(identityFn) : results.every(identityFn);

  return negate ? !result : result;
}

function check(value: AnswerType, rule: ConditionalRule): boolean {
  const operator = OPERATORS[rule.operator || ""];
  return (
    !operator ||
    operator.check({
      answer: value,
      value: rule.value,
    })
  );
}
