import React, {
  ComponentType,
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { Field } from "../types/form";
import { pick, PickMany } from "src/global/objectUtils";
import { jumpTo, validateAnswer } from "./bounce";
import { StringMap, TYPE_DATE } from "src/global";
import validateJs from "validate.js";
import moment from "moment";
import { IPlainInputProps, PlainFn, TypedReactMemo } from "../types";
import { getFieldMeta } from "../fieldMetas";
import isFunction from "lodash/isFunction";
import { AnswerState, AnswerType } from "./types";

const checkEmpty = (value: any) =>
  !!validateJs.single(value, { presence: { allowEmpty: false } });

interface AnswerInputConfig<T, K> {
  defaultValue: T;
  keys: K[];
  isEmpty?: (value: T) => boolean;
  constraints?: StringMap;
  cast?: (value: any) => T;
}

export interface AnswerInputProps<T extends AnswerType> {
  field: Field;
  value?: T;
  onChange: (answerState: AnswerState<T>) => void;
  onRequestNext: PlainFn;
}

function AnswerInput<T extends AnswerType, K extends keyof Field>(
  config: AnswerInputConfig<T, K>
) {
  const {
    defaultValue,
    keys,
    constraints = {},
    isEmpty = checkEmpty,
    cast,
  } = config;

  return function Wrapper(
    Component: ComponentType<PickMany<Field, Array<K>> & IPlainInputProps<T>>
  ) {
    const MemoizedComponent = TypedReactMemo(Component);

    const AnswerInputWrapper: FC<AnswerInputProps<T>> = (props) => {
      const { field, value, onChange, onRequestNext } = props;
      const slice = pick(field, keys);

      // Process answer
      const {
        required,
        validations,
        jumps,
        defaultJumpTarget,
        type,
        dateFormat,
      } = field;
      const saveAnswer = useCallback(
        (value: T, authorizeAutoJump: boolean) => {
          const hasAnswer = !isEmpty(value);

          const getError = () => {
            // Validation only happens when an answer is given
            if (!hasAnswer)
              return required ? "Answer can not be empty" : undefined;

            // Collector specific validation constraints
            const jsValue =
              type === TYPE_DATE
                ? moment.utc(value as string, dateFormat)
                : value;
            const error = validateJs.single(jsValue, constraints);
            if (error) return error;

            // User defined validations
            return validateAnswer(value, validations);
          };

          const getTarget = () =>
            hasAnswer
              ? jumpTo(value, jumps, defaultJumpTarget)
              : defaultJumpTarget;

          onChange({
            answer: cast ? cast(value) : value,
            showError: false,
            error: getError(),
            jumpTo: getTarget(),
            authorizeAutoJump,
          });
        },
        [
          onChange,
          required,
          validations,
          jumps,
          defaultJumpTarget,
          type,
          dateFormat,
        ]
      );

      // Set the answer on first render
      useEffect(() => {
        if (value === undefined) saveAnswer(defaultValue, false);
      }, [value, saveAnswer]);

      // Handle answer change
      const handleChange = useCallback(
        (value: T) => saveAnswer(value, true),
        [saveAnswer]
      );

      const shouldAutoJumpOnAnswer = (() => {
        const { canAutoJump } = getFieldMeta(field.type);
        return isFunction(canAutoJump) ? canAutoJump(field) : !!canAutoJump;
      })();

      const prevAnswer = useRef(value);
      useEffect(() => {
        if (shouldAutoJumpOnAnswer && prevAnswer.current !== value) {
          prevAnswer.current = value;
          setTimeout(onRequestNext, 100);
        }
      }, [value, shouldAutoJumpOnAnswer, onRequestNext]);

      // Only render when we have a valid value
      if (value === undefined) return null;

      return (
        <MemoizedComponent {...slice} onChange={handleChange} value={value} />
      );
    };

    return memo(AnswerInputWrapper);
  };
}

export default AnswerInput;
