import isArray from 'lodash/isArray';

type CompoundKeyOf<Type> = keyof Type | Array<keyof Type>;

export type PickMany<Type, Key extends Array<keyof Type>> = {
  [K in Extract<keyof Type, Key[number]>]: Type[K]
};

type CompoundPick<Type extends object, Key extends CompoundKeyOf<Type>> =
  Key extends Array<keyof Type> ? PickMany<Type, Key> :
    Key extends keyof Type ? Pick<Type, Key> : never;

function slice<Type extends object, Key extends CompoundKeyOf<Type>>(
  params: Type, keys: Key, exclude?: boolean) {
  const isKeyProvided = (key: keyof Type) =>
    isArray(keys) ? keys.includes(key) : keys === key;

  const isValid = (key: keyof Type) =>
    exclude ? !isKeyProvided(key) : isKeyProvided(key);

  return (Object.keys(params) as Array<keyof Type>)
    .reduce(
      (obj, key) =>
        isValid(key) ? { ...obj, [key]: params[key] } : obj,
      {});
}

export function pick<Type extends object, Key extends Array<keyof Type>>(params: Type, keys: Key): PickMany<Type, Key>;
export function pick<Type extends object, Key extends keyof Type>(params: Type, key: Key): Pick<Type, Key>;
export function pick<Type extends object, Key extends CompoundKeyOf<Type>>(params: Type, keys: Key) {
  return slice(params, keys, false) as CompoundPick<Type, Key>;
}

export function omit<Type extends object, Key extends keyof Type>(params: Type, keys: Key): Omit<Type, Key>;
export function omit<Type extends object, Key extends Array<keyof Type>>(params: Type, keys: Key): Omit<Type, Extract<keyof Type, Key[number]>>;
export function omit<Type extends object, Key extends CompoundKeyOf<Type>>(params: Type, keys: Key) {
  return slice(params, keys, true) as Omit<Type, keyof CompoundPick<Type, Key>>;
}
