import React, { ChangeEventHandler, useCallback } from 'react';
import MaskedDateInput from '../ui/MaskedDateInput';
import AnswerInput from './AnswerInput';
import { TextField } from '@material-ui/core';

export default AnswerInput({
  defaultValue: '' as string,
  constraints: { date: true },
  keys: ['dateFormat'],
})(props => {
  const { onChange, value, dateFormat = 'DD/MM/YYYY' } = props;

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => onChange(e.target.value),
    [onChange]);

  return (
    <TextField
      fullWidth
      defaultValue={value}
      onChange={handleChange}
      variant="outlined"
      placeholder={dateFormat}
      helperText={dateFormat}
      InputProps={{
        inputComponent: MaskedDateInput,
        inputProps: { format: dateFormat },
      }}/>
  );
});
