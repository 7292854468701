import { SetStateAction } from 'react';

export type UpdateAction<T> = (prevState: T) => T

function isUpdateAction<T>(x: SetStateAction<T>): x is UpdateAction<T> {
  return typeof x === 'function';
}

export function applyAction<T>(value: SetStateAction<T>, prevState: T): T {
  return isUpdateAction(value) ? value(prevState) : value;
}

export type SetStateActionHandler<T> = (action: SetStateAction<T>) => void

export type VoidAction<T extends (...args: any[]) => any> = (...args: Parameters<T>) => void;
