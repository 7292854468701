import React, { memo } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

interface ErrorMessageProps {
  message: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-block',
    color: red[500],
    border: `1px solid ${red[600]}`,
    borderRadius: 4,
    padding: theme.spacing(0, 2),
  },
}));

const ErrorMessage: React.FC<ErrorMessageProps> = props => {
  const classes = useStyles();

  return (
    <Typography className={classes.root}>
      {props.message}
    </Typography>
  );
};

export default memo(ErrorMessage);
