import React from "react";
import AutoSuggest from "./AutoSuggest";
import useChoices, { ChoicesSliceKeys } from "../hooks/useChoices";
import AnswerInput from "./AnswerInput";

export default AnswerInput({
  defaultValue: "" as string,
  keys: ChoicesSliceKeys,
})((props) => {
  const { value, onChange, ...choiceSlice } = props;
  const choices = useChoices(choiceSlice);

  return (
    <AutoSuggest
      suggestions={choices.map((label) => ({ label }))}
      value={value}
      onChange={onChange}
    />
  );
});
