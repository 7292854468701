import React from "react";
import { RouteComponentProps } from "react-router";
import { APP_NAME, StringMap, WithID } from "src/global";
import { LookAndFeel } from "src/shared/types/theme";
import { makeStyles } from "@material-ui/core";
import ResponseCollector from "src/shared/ResponseCollector";
import FormClosedPage from "src/shared/ResponseCollector/FormClosedPage";
import { FormResponseData } from "src/shared/types/response";
import { parseQueryString } from "src/shared/helpers";
import LoadingSpinner from "src/shared/ui/LoadingSpinner";
import SurveysResource from "src/api/SurveysResource";
import { Form } from "src/shared/types/form";
import useNotification from "src/shared/hooks/useNotification";

const useStyles = makeStyles({
  root: {
    height: "100vh",
  },
});

const SAVED_LOCALLY_MSG =
  "Your response has been saved locally. We will automatically retry the submission later.";

const CollectorPage: React.FC<RouteComponentProps<WithID>> = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const { embed, popup } = parseQueryString(
    props.location.search
  ) as StringMap<string>;
  const { onShowInfo } = useNotification();

  const [theme, setTheme] = React.useState<LookAndFeel | undefined>(undefined);
  const [form, setForm] = React.useState<Form | undefined>(undefined);
  const [closed, setClosed] = React.useState(false);

  const handleSubmit = React.useCallback(
    (data: FormResponseData) =>
      new Promise<void>((resolve) => {
        data = { ...data, embed: embed };

        SurveysResource.save(data)
          .catch(() => onShowInfo(SAVED_LOCALLY_MSG))
          .then(() => resolve());
      }),
    [onShowInfo, embed]
  );

  React.useEffect(() => {
    // @ts-ignore
    window.prerenderReady = false;
    SurveysResource.get({ id })
      .then((res) => {
        setClosed(res.closed);
        if (res.closed) setTheme(res.theme);
        else {
          setForm(res.form);
          setTheme(res.form.theme);
        }
      })
      .catch(() => setClosed(true));
  }, [id]);

  const title = form && form.name ? form.name : "Online form";
  React.useEffect(() => {
    document.title = `${title} | ${APP_NAME}`;
  }, [title]);

  const onRenderComplete = () => {
    setTimeout(() => {
      // @ts-ignore
      window.prerenderReady = true;
    }, 10);
  };

  if (closed && theme) {
    onRenderComplete();
    return <FormClosedPage theme={theme} />;
  }

  if (!theme || !form) return <LoadingSpinner vh text />;

  onRenderComplete();
  return (
    <div className={classes.root}>
      <ResponseCollector
        theme={theme}
        form={form}
        onSubmit={handleSubmit}
        closable={Boolean(popup)}
      />
    </div>
  );
};

export default CollectorPage;
