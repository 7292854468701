import React, { ComponentType, memo, useCallback } from "react";
import FieldPositionIconBlock from "./FieldPositionIconBlock";
import FieldTitle from "./FieldTitle";
import FieldDescription from "./FieldDescription";
import AnswerInputContainer from "./AnswerInputContainer";
import ErrorMessage from "./ErrorMessage";
import BaseCollectorControls from "./BaseCollectorControls";
import FieldContainer from "./FieldContainer";
import ArrowRight from "@material-ui/icons/ArrowForward";
import { AnswerInputProps } from "./AnswerInput";
import { AnswerState, AnswerType, BaseCollectorProps } from "./types";

export interface CollectorProps<T extends AnswerType = AnswerType>
  extends BaseCollectorProps {
  value?: AnswerState<T>;
  onChange: (value: AnswerState<T>) => void;
}

export default function AnswerInputHOC<T extends AnswerType>(
  Input: ComponentType<AnswerInputProps<T>>
) {
  const DEFAULT_VALUE: AnswerState<T> = {};

  return memo((props: CollectorProps<T>) => {
    const {
      onChange,
      value = DEFAULT_VALUE,
      field,
      position,
      onGoNext,
      onGoBack,
    } = props;
    const error = value.showError ? value.error : undefined;

    const { authorizeAutoJump } = value;
    const requestAutoJump = () => {
      authorizeAutoJump && onGoNext();
    };

    return (
      <FieldContainer>
        <FieldPositionIconBlock icon={<ArrowRight />} position={position} />

        <FieldTitle title={field.title} required={field.required} />

        <FieldDescription
          enabled={field.descriptionEnabled}
          text={field.description}
        />

        <AnswerInputContainer>
          <Input
            field={field}
            value={value.answer}
            onChange={onChange}
            onRequestNext={requestAutoJump}
          />
        </AnswerInputContainer>

        {error && <ErrorMessage message={error} />}

        {!error && (
          <BaseCollectorControls
            onNext={onGoNext}
            onPrev={onGoBack}
            buttonText={"OK"}
          />
        )}
      </FieldContainer>
    );
  });
}
