import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(15),
      paddingRight: theme.spacing(15),
    },
  },
}));

const HorizontalPositionedContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const classes = useStyles();
  const { className, ...otherProps } = props;
  return <div {...otherProps} className={clsx(classes.root, className)}/>;
};

export default HorizontalPositionedContainer;
