import React from 'react';
import { useIsSmallScreen } from '../helpers';

export interface CellProps extends React.HTMLAttributes<HTMLDivElement> {
  middle?: boolean
  span?: number
  xsSpan?: number
  area?: string
}

const Cell: React.FC<CellProps> = props => {
  const { middle, span, xsSpan, area, style, ...divProps } = props;
  const isSmall = useIsSmallScreen();
  const cellSpan = isSmall ? xsSpan || span : span;

  const cellStyle = React.useMemo(
    () => ({
      ...(middle && {
        display: 'inline-flex',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        justifySelf: 'stretch',
      }),
      ...(area && {
        gridArea: area
      }),
      gridColumnEnd: `span ${cellSpan}`,
      gridRowEnd: 'span 1',
      minHeight: 0,
      minWidth: 0,
      ...style,
    }),
    [middle, cellSpan, style, area]);

  return (
    <div
      {...divProps}
      style={cellStyle}/>
  );
};

Cell.defaultProps = {
  middle: false,
  span: 1,
};

export default Cell;
