import React, { memo } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import ThemedButton from './ThemedButton';
import DoneIcon from '@material-ui/icons/DoneOutlined';
import Spacer from '../ui/Spacer';
import { useFormTheme } from './theme';

interface BaseCollectorControlsProps {
  onNext: React.MouseEventHandler
  onPrev: React.MouseEventHandler
  buttonText: string
}

const useStyles = makeStyles((theme: Theme) => ({
  nextButtonText: {
    marginRight: theme.spacing(3),
  },
  prevButtonText: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    fontSize: 14,
  },
}));

const BaseCollectorControls: React.FC<BaseCollectorControlsProps> = props => {
  const classes = useStyles();
  const { buttonColor } = useFormTheme();

  return (
    <div>
      <ThemedButton color={buttonColor} onClick={props.onNext}>
        <span className={classes.nextButtonText}>
          {props.buttonText}
        </span>
        <DoneIcon/>
      </ThemedButton>
      <Spacer/>
      <ThemedButton color={buttonColor} size="small" onClick={props.onPrev}>
        <span className={classes.prevButtonText}>
          Back
        </span>
      </ThemedButton>
    </div>
  );
};

export default memo(BaseCollectorControls);
