import MaskedInput from "react-text-mask";
import React from "react";
import { InputBaseComponentProps } from "@material-ui/core/InputBase";
import { StringMap } from "src/global";

interface MaskedDateInputProps extends InputBaseComponentProps {
  format?: string;
}

const nonAlphabetic = /[^A-Z]+/i;

const MASKS: StringMap<RegExp[]> = {
  DD: [/[0123]/, /\d/],
  MM: [/\d/, /\d/],
  YYYY: [/\d/, /\d/, /\d/, /\d/],
};

const MaskedDateInput: React.FC<MaskedDateInputProps> = (props) => {
  const { inputRef, format, ...others } = props;

  const mask = React.useMemo(() => {
    const separator = format!.match(nonAlphabetic)![0];
    const chunks = format!.split(nonAlphabetic);

    const arr: Array<string | RegExp> = [];
    chunks.forEach((chunk) => {
      if (arr.length > 0) arr.push(separator);
      arr.push(...MASKS[chunk]);
    });
    return arr;
  }, [format]);

  return (
    <MaskedInput
      {...others}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      keepCharPositions
    />
  );
};

MaskedDateInput.defaultProps = {
  format: "DD-MM-YYYY",
};

export default React.memo(MaskedDateInput);
