import React from "react";
import { StringMap } from "src/global";

export type Callback<Value, Return, Key> = (key: Key, value: Value) => Return;
export type Handler<Value, Return> = (value: Value) => Return;
export type CacheMaker<Value, Return, Key> = (
  key: Key
) => Handler<Value, Return>;

export default function useCachedCallback<
  Value = any,
  Return = void,
  Key = string
>(callback: Callback<Value, Return, Key>): CacheMaker<Value, Return, Key> {
  const cacheBox = React.useRef<StringMap<Handler<Value, Return>>>({});
  const cache = cacheBox.current;

  // Clear the cache when callback changes
  React.useEffect(() => {
    cacheBox.current = {};
  }, [callback]);

  // Get or Create a handler
  return React.useMemo(
    () => (key) => {
      const sKey = key as unknown as string;
      if (!cache.hasOwnProperty(sKey))
        cache[sKey] = (value: Value) => callback(key, value);
      return cache[sKey];
    },
    [cache, callback]
  );
}
