import React from 'react';
import { BoxProps } from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/styles';
import { LookAndFeel } from '../types/theme';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(createStyles({
  root: (theme: LookAndFeel) => {
    const bg = theme.backgroundImage;
    const position = "relative" as "relative";

    if (!bg || !bg.image) {
      return {
        position,
        backgroundColor: theme.backgroundColor,
        '&::before': {
          display: 'none'
        }
      };
    }

    const {brightness = 0, layout = 'cover', image} = bg;
    const c = brightness < 0 ? 0 : 256;
    const bgColor = `rgba(${c}, ${c}, ${c}, ${Math.abs(brightness)})`;

    return {
      position,
      backgroundPosition: "top center",
      backgroundImage: `url(${image})`,
      backgroundSize: layout === 'cover' ? layout : undefined,
      backgroundRepeat: layout === 'cover' ? undefined : layout,
      backgroundColor: bgColor,

      '&::before': {
        display: 'block',
        content: "''",
        position: 'absolute' as "absolute",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: bgColor
      }
    }
  }
}));

type OmitProps = 'className' | 'position' | 'bgcolor';

interface ThemedBackgroundBoxProps extends Omit<BoxProps, OmitProps> {
  theme: LookAndFeel
}

const ThemedBackgroundBox: React.FC<ThemedBackgroundBoxProps> = ({theme, ...others}) => {
  const classes = useStyles(theme);
  return <Box {...others} className={classes.root} />;
};

export default ThemedBackgroundBox;
