import React from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';
import Grid from '../ui/Grid';
import Cell from '../ui/Cell';
import CheckBoxChecked from '@material-ui/icons/CheckBox';
import CheckBoxBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import RadioChecked from '@material-ui/icons/RadioButtonChecked';
import RadioUnChecked from '@material-ui/icons/RadioButtonUnchecked';
import { ButtonProps } from '@material-ui/core/Button';

interface ChoiceItemProps extends ButtonProps {
  label: string
  active: boolean
  multi?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  textContainer: {
    width: "100%"
  },
  label: {
    textAlign: "left"
  }
}));

const ChoiceItem: React.FC<ChoiceItemProps> = props => {
  const classes = useStyles();
  const { label, active, multi, ...others } = props;

  const Icon = multi
    ? (active ? CheckBoxChecked : CheckBoxBlank)
    : (active ? RadioChecked : RadioUnChecked);

  return (
    <div className={classes.root}>
      <Button
        {...others}
        fullWidth
        color="primary"
        size="small"
        variant="outlined">
        <Grid columns="1fr auto" gap={4} className={classes.textContainer}>
          <Cell middle className={classes.label}>
            {label}
          </Cell>
          <Cell middle>
            <Icon/>
          </Cell>
        </Grid>
      </Button>
    </div>
  );
};

export default React.memo(ChoiceItem);
