import React from 'react';
import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import AlignMiddle from './AlignMiddle';
import clsx from 'clsx';

const color = grey[500];

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    position: 'relative',
  },
  vhHeight: {
    height: '100vh',
  },
  pxHeight: {
    height: 120
  },
  pxHeightWithText: {
    height: 180
  },
  description: {
    color: grey[600],
    fontSize: '0.9em',
  },
  title: {
    fontSize: '1.2em',
    fontWeight: 700,
  },
  spinner: {
    display: 'inline-block',
    position: 'relative',
    width: 80,
    height: 80,

    '&:after': {
      content: '" "',
      display: 'inline-block',
      borderRadius: '50%',
      width: 0,
      height: 0,
      margin: 8,
      boxSizing: 'border-box',
      border: `22px solid ${color}`,
      borderColor: `${color} transparent ${color} transparent`,
      animation: '$hourglass 1.2s infinite',
    },
  },

  '@keyframes hourglass': {
    '0%': {
      transform: 'rotate(0)',
      animationTimingFunction: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
    },
    '50%': {
      transform: 'rotate(900deg)',
      animationTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
    },
    '100%': {
      transform: 'rotate(1800deg)',
    },
  },
});

interface LoadingSpinnerProps {
  vh?: boolean
  px?: boolean
  text?: boolean
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = props => {
  const classes = useStyles();
  const { px, vh, text } = props;

  return (
    <div
      className={clsx({
        [classes.root]: 1,
        [classes.vhHeight]: vh,
        [classes.pxHeight]: px,
        [classes.pxHeightWithText]: px && text
      })}>
      <AlignMiddle>
        <div className={classes.spinner}/>
        {text && (
          <>
            <div className={classes.description}>Powered by</div>
            <div className={classes.title}>JibuSasa</div>
          </>
        )}
      </AlignMiddle>
    </div>
  );
};

export default React.memo(LoadingSpinner);
