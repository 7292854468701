import React from 'react';
import Grid, { GridProps } from './Grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

type FullHeightGridProps = GridProps & {
  vh?: boolean
}

const useStyles = makeStyles({
  vh: {
    height: '100vh',
  },
  percent: {
    height: '100%',
  },
});


const FullHeightGrid: React.FC<FullHeightGridProps> = props => {
  const classes = useStyles();
  const { vh, className, ...others } = props;

  return (
    <Grid
      {...others}
      className={clsx(className, {
        [classes.percent]: !vh,
        [classes.vh]: !!vh,
      })}/>
  );
};

export default FullHeightGrid;
