import { SetStateAction, useCallback } from 'react';
import useCachedCallback, { Handler } from './useCachedCallback';
import { applyAction, SetStateActionHandler } from '../actions';

export default function useMapChangeMaker<MapType>(
  onChange: SetStateActionHandler<MapType>,
) {
  const handleChange = useCallback(
    <Key extends keyof MapType>(
      key: Key,
      value: SetStateAction<MapType[Key]>
    ) => {
      onChange(state => ({
        ...state,
        [key]: applyAction(value, state[key])
      }));
    },[onChange]);

  type CacheMaker =
    <Key extends keyof MapType>(key: Key) => Handler<SetStateAction<MapType[Key]>, void>;

  return useCachedCallback(handleChange) as CacheMaker;
}

/**
 * Dirty Hack: Fixes typings
 * Used when value could be null, but are checked in the code before use
 */
export const nonNullableHandlerHack =
  <T>(handler: Handler<SetStateAction<T>, void>) =>
    handler as Handler<SetStateAction<NonNullable<T>>, void>;
