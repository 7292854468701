import React from 'react';
import { LookAndFeel } from '../types/theme';
import FieldContainer from './FieldContainer';
import { Link, makeStyles, Theme } from '@material-ui/core';
import FieldTitle from './FieldTitle';
import ThemedButton from './ThemedButton';
import ThemeContainer from './ThemeContainer';
import FullHeightGrid from '../ui/FullHeightGrid';
import Cell from '../ui/Cell';
import { FormThemeProvider } from './theme';

interface FormClosedProps {
  theme: LookAndFeel
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(4),
  },

  container: {
    height: 'auto',
  },

  innerContainer: {
    textAlign: 'center',
  },

  messageContainer: {
    marginTop: theme.spacing(6),
  },

  description: {
    opacity: .9,
    marginBottom: theme.spacing(2),
  },

  buttonText: {
    marginRight: theme.spacing(3),
  },
}));

const FormClosedPage: React.FC<FormClosedProps> = props => {
  const classes = useStyles();
  const { theme } = props;

  return (
    <FormThemeProvider value={theme}>
      <ThemeContainer heightUnit="vh">
        <FullHeightGrid columns="1fr">
          <Cell middle className={classes.root}>
            <FieldContainer className={classes.container}>
              <div className={classes.innerContainer}>
                <FieldTitle
                  title="This form is now closed"/>

                <div className={classes.messageContainer}>
                  <div style={{ color: theme.titleColor }} className={classes.description}>
                    Create your own — it's free, easy & beautiful
                  </div>
                  <Link target="_blank" href={window.location.host} underline="none">
                    <ThemedButton color={theme.buttonColor}>
                    <span className={classes.buttonText}>
                      Create a form
                    </span>
                    </ThemedButton>
                  </Link>
                </div>
              </div>
            </FieldContainer>
          </Cell>
        </FullHeightGrid>
      </ThemeContainer>
    </FormThemeProvider>
  );
};

export default React.memo(FormClosedPage);
