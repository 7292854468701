import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
});

type ScrollableProps = React.HTMLAttributes<HTMLDivElement>;

const Scrollable = React.forwardRef<HTMLDivElement, ScrollableProps>(
  (props, ref) => {
    const classes = useStyles();
    const { className, ...otherProps } = props;

    return (
      <div
        {...otherProps}
        ref={ref}
        className={clsx(className, classes.root)}/>
    );
  },
);

export default Scrollable;
