import React from 'react';
import { blue } from '@material-ui/core/colors';
import { Button, makeStyles, Theme } from '@material-ui/core';

interface UpdateNotificationProps {
  onClick: React.MouseEventHandler
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 8),
    textAlign: 'center',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${blue[300]}`,
    borderRadius: 4,
    boxShadow: '0 4px 16px rgba(100, 181, 246, .78)',
    position: 'fixed',
    bottom: '1em',
    right: '1em',
    left: '1em',
    [theme.breakpoints.up('sm')]: {
      left: 'auto',
    },
  },

  message: {
    marginBottom: theme.spacing(2),
  },

  buttonText: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'inline-block',
  },
}));

const UpdateNotification: React.FC<UpdateNotificationProps> = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.message}>
        New content available
      </div>
      <Button variant="contained" color="default" size="small" onClick={props.onClick}>
        <span className={classes.buttonText}>
          Refresh
        </span>
      </Button>
    </div>
  );
};

export default React.memo(UpdateNotification);
