import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  outer: {
    display: 'table',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },

  middle: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },

  inner: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
});

const AlignMiddle: React.FC = props => {
  const classes = useStyles();

  return (
    <div className={classes.outer}>
      <div className={classes.middle}>
        <div className={classes.inner}>
          <div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlignMiddle;
