import React, { MouseEvent, useCallback, useMemo } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { range } from '../helpers';
import { ThemeProvider } from '@material-ui/styles';
import * as colorManipulator from '@material-ui/core/styles/colorManipulator';
import { useTheme } from '@material-ui/core';
import OpinionScaleLabels from './OpinionScaleLabels';
import AnswerInput from './AnswerInput';
import { useFormTheme } from './theme';

const useCollectorTheme = () => {
  const theme = useTheme();
  const { answerColor, fontFamily } = useFormTheme();
  return useMemo(() => {
    return {
      ...theme,
      overrides: {
        ...theme.overrides,
        // @ts-ignore
        MuiToggleButtonGroup: {
          root: {
            display: 'flex',
            borderRadius: 4,
            backgroundColor: 'transparent',
          },
        },
        MuiToggleButton: {
          root: {
            height: '64px',
            borderColor: answerColor,
            color: answerColor,
            marginLeft: '0 !important',
            fontFamily: fontFamily,
            flexGrow: 1,
            minWidth: '0 !important',
            backgroundColor: 'transparent',  // theme.second,

            '&:first-child': {
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            },

            '&:last-child': {
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            },

            '&:hover': {
              backgroundColor: colorManipulator.lighten(answerColor, 0.8),
            },
          },

          selected: {
            color: answerColor,
            backgroundColor: `${colorManipulator.lighten(answerColor, 0.7)} !important`,
          },
        },
      },
    };
  }, [theme, answerColor, fontFamily]);
};

export default AnswerInput({
  defaultValue: null as number | null,
  keys: ['steps', 'labelsEnabled', 'labels'],
})(props => {
  const theme = useCollectorTheme();
  const { value, onChange, steps, labels, labelsEnabled } = props;

  const handleChange = useCallback(
    (event: MouseEvent<HTMLElement>, value: any) => onChange(value),
    [onChange]);

  return (
    <ThemeProvider theme={theme}>
      <ToggleButtonGroup
        exclusive
        size="large"
        value={value}
        onChange={handleChange}>
        {range(steps, 1).map(value => (
          <ToggleButton
            value={value}
            color="primary"
            key={value}>
            {value}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      <OpinionScaleLabels
        enabled={labelsEnabled}
        labels={labels}/>
    </ThemeProvider>
  );
});
