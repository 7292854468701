import React, { cloneElement, memo, ReactElement } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import Grid from '../ui/Grid';
import Cell from '../ui/Cell';
import { Position } from '../helpers';
import isNumber from 'lodash/isNumber';
import { useColorStyle } from './utils';
import { useFormTheme } from './theme';

interface PositionIconBlockProps {
  position?: Position
  icon: ReactElement
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontWeight: 'bold',
    position: 'absolute',
    top: 0,
    right: '100%',
    fontSize: '16px',
    lineHeight: '24px',
    margin: theme.spacing(1.5, 2, 0, 0),
    width: 50,
    textAlign: 'right',
    display: 'none',

    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
  },
  icon: {
    fontSize: '14px',
    lineHeight: '21px',
  },
}));

const FieldPositionIconBlock: React.FC<PositionIconBlockProps> = props => {
  const classes = useStyles();
  const { answerColor } = useFormTheme();
  const colorStyle = useColorStyle(answerColor);
  const { icon, position } = props;

  return (
    <div className={classes.root} style={colorStyle}>
      <Grid columns="1fr auto" gap={0}>
        <Cell middle>
          <span>{isNumber(position) && position}</span>
        </Cell>
        <Cell middle>
          {cloneElement(icon, { className: classes.icon })}
        </Cell>
      </Grid>
    </div>
  );
};

export default memo(FieldPositionIconBlock);
