import React from 'react';
import FieldContainer from './FieldContainer';
import { makeStyles } from '@material-ui/core';
import FieldTitle from './FieldTitle';
import ClearIcon from '@material-ui/icons/ClearRounded';
import { red } from '@material-ui/core/colors';
import CreateYourOwnFormButton from './CreateYourOwnFormButton';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
  icon: {
    fontSize: '146px',
    color: red[800],
  },
});

const ScreeningFail: React.FC = () => {
  const classes = useStyles();

  return (
    <FieldContainer>
      <div className={classes.root}>
        <ClearIcon className={classes.icon}/>
        <FieldTitle title="We regret you do not qualify for this survey"/>
        <CreateYourOwnFormButton/>
      </div>
    </FieldContainer>
  );
};

export default React.memo(ScreeningFail);
