import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import ratingShapeItems from "../ratingShapeItems";
import { getItemById, range } from "../helpers";
import { useColorStyle } from "./utils";
import useCachedCallback from "../hooks/useCachedCallback";
import AnswerInput from "./AnswerInput";
import { useFormTheme } from "./theme";

const useStyles = makeStyles({
  iconRoot: {
    height: "100%",
    width: "100%",
    maxWidth: 64,
    maxHeight: 64,
  },
  gridItem: {
    maxWidth: 64,
  },
  label: {
    textAlign: "center",
  },
});

export default AnswerInput({
  defaultValue: null as number | null,
  keys: ["steps", "shape"],
})((props) => {
  const { value, onChange, steps, shape } = props;
  const styles = useStyles();
  const { answerColor } = useFormTheme();
  const colorStyle = useColorStyle(answerColor);
  const { Icon, IconFilled } = getItemById(shape, ratingShapeItems);
  const makeClickHandler = useCachedCallback(onChange);

  return (
    <Grid container>
      {range(steps, 1).map((itemValue) => {
        const IconComponent =
          value !== null && itemValue <= value ? IconFilled : Icon;

        return (
          <Grid item xs key={itemValue} className={styles.gridItem}>
            <IconComponent
              color="primary"
              onClick={makeClickHandler(itemValue)}
              className={styles.iconRoot}
            />
            <div className={styles.label} style={colorStyle}>
              {itemValue}
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
});
