import Api from 'src/shared/Api';
import { WithID } from 'src/global';
import { FormResponseData } from 'src/shared/types/response';
import { LookAndFeel } from 'src/shared/types/theme';
import { Form } from 'src/shared/types/form';


interface ClosedSurveyResponse {
  closed: true
  theme: LookAndFeel
}

interface OpenSurveyResponse {
  closed: false
  form: Form
}

type SurveyResponse = OpenSurveyResponse | ClosedSurveyResponse;

const BASE = '/surveys/:id';

export default class SurveysResource {
  static get = (params: WithID) =>
    Api.get<SurveyResponse>(Api.replace(BASE, params));

  static save = (data: FormResponseData) =>
    Api.post(Api.replace(BASE, { id: data.form }), data);
}
