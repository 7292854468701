import isString from "lodash/isString";

export const GROUP_NUMERIC = "numeric";
export const TYPE_RATING = "rating";
export const TYPE_OPINION_SCALE = "opinion_scale";
export const TYPE_NUMBER = "number";
export const isNumericType = (type: string) =>
  [TYPE_NUMBER, TYPE_RATING, TYPE_OPINION_SCALE].includes(type);

export const GROUP_CHOICE = "choice";
export const TYPE_MULTI_CHOICE = "choices";
export const TYPE_DROPDOWN = "dropdown";
export const isChoiceType = (type: string) =>
  [TYPE_MULTI_CHOICE, TYPE_DROPDOWN].includes(type);

export const GROUP_TEXT = "text";
export const TYPE_TEXT = "text";
export const TYPE_LONG_TEXT = "long_text";
export const TYPE_PHONE_NUMBER = "phone_number";
export const TYPE_EMAIL = "email";
export const TYPE_URL = "url";
export const isTextType = (type: string) =>
  [TYPE_TEXT, TYPE_LONG_TEXT, TYPE_PHONE_NUMBER, TYPE_EMAIL, TYPE_URL].includes(
    type
  );

export const GROUP_DATE = "date";
export const TYPE_DATE = "date";

export const GROUP_NULL = "null";
export const TYPE_STATEMENT = "statement";
export const TYPE_WELCOME_SCREEN = "welcome_screen";

export const TYPE_LOCATION = "location";

export const JUMP_TARGET_END = "END";
export const JUMP_TARGET_DISQUALIFY = "DISQUALIFY";

export const GOOGLE_FONT_API_KEY = "AIzaSyDqG5Xijh3Z8VrXohuHSf5iAcIQBdvmE7s";
export const APP_NAME = "JibuSasa";
export const OTHER_TEXT = "Other";

export const ADMIN_USER_ID = String("admin");
export const isAdmin = (userID: any) => String(userID) === ADMIN_USER_ID;

export const PROGRESS_BAR_PERCENTAGE = "percentage";
export const PROGRESS_BAR_PROPORTION = "proportion";

export type ProgressBarType =
  | typeof PROGRESS_BAR_PERCENTAGE
  | typeof PROGRESS_BAR_PROPORTION;

export const FORM_PUT_KEYS =
  `name|fields|theme|uniqueResponseRules|smsMessage|responseCompletedWebhook|
  isPublic|progressBar|ownNotification|geoLocation|thankYouMessage`
    .split("|")
    .map((x) => x.trim());

// Pay Per Use
export const COST_PER_UNIT_KES = 10;
export const USD_EQUIV_IN_KES = 100;
export const MIN_UNIT_COUNT = 500;
export const KESToUSD = (n: number) => n / USD_EQUIV_IN_KES;
export const USDToUnits = (n: number) =>
  Math.ceil((n * USD_EQUIV_IN_KES) / COST_PER_UNIT_KES);

export const USE_PAYPAL_SANDBOX_ENV = false;
export const isLivePaypal = () =>
  !USE_PAYPAL_SANDBOX_ENV || process.env.NODE_ENV !== "development";

export const ACCESS_LEVEL_OWNER = "owner"; // Form & Dashboard
export const ACCESS_LEVEL_ADMIN = "admin"; // Form & Dashboard
export const ACCESS_LEVEL_BUILDER = "builder"; // Form Only
export const ACCESS_LEVEL_COLLECTOR = "collector"; // Form Only
export const ACCESS_LEVEL_VIEW = "view"; // Dashboard Only

export type CollaboratorAccessLevel =
  | typeof ACCESS_LEVEL_OWNER
  | typeof ACCESS_LEVEL_ADMIN
  | typeof ACCESS_LEVEL_BUILDER
  | typeof ACCESS_LEVEL_COLLECTOR
  | typeof ACCESS_LEVEL_VIEW;

export const ORDERED_ACCESS_LEVELS: CollaboratorAccessLevel[] = [
  ACCESS_LEVEL_VIEW,
  ACCESS_LEVEL_COLLECTOR,
  ACCESS_LEVEL_BUILDER,
  ACCESS_LEVEL_ADMIN,
  ACCESS_LEVEL_OWNER,
];

export const sortCompareDate = (a: Date | string, b: Date | string) => {
  const time = (dateString: string | Date) => new Date(dateString).getTime();
  return time(a) - time(b);
};

export const createSortCompareDate =
  <T>(getValue: (item: T) => string, reverse?: boolean) =>
  (a: T, b: T) =>
    sortCompareDate(getValue(a), getValue(b)) * (reverse ? -1 : 1);

export const sortCompareAccessLevel = (
  a: CollaboratorAccessLevel,
  b: CollaboratorAccessLevel
) => {
  const ai = ORDERED_ACCESS_LEVELS.indexOf(a);
  const bi = ORDERED_ACCESS_LEVELS.indexOf(b);
  return ai - bi;
};

export const hasClearance = (
  requestedAccess: CollaboratorAccessLevel,
  grantedAccess: CollaboratorAccessLevel
) => sortCompareAccessLevel(grantedAccess, requestedAccess) >= 0;

export const STATUS_PENDING = "pending";
export const STATUS_DECLINED = "declined";
export const STATUS_ACCEPTED = "accepted";

export type CollaboratorStatus =
  | typeof STATUS_PENDING
  | typeof STATUS_ACCEPTED
  | typeof STATUS_DECLINED;

export const RECHARGE_METHOD_FREE = "FREE";
export const RECHARGE_METHOD_PAYPAL = "PAYPAL";
export const RECHARGE_METHOD_MPESA = "MPESA";

export type UnitRechargeMethod =
  | typeof RECHARGE_METHOD_FREE
  | typeof RECHARGE_METHOD_PAYPAL
  | typeof RECHARGE_METHOD_MPESA;

export const DOMAIN_NAME = "jibusasa.com";
export const CLIENT_SUB_DOMAIN = "app";
export const CLIENT_LOCALHOST_PORT = 9996;
export const RESPONDENT_SUB_DOMAIN = "s";
export const RESPONDENT_LOCALHOST_PORT = 9997;

type HasType = { type: string };

export function isAnswerable(what: string | HasType) {
  const type = typeof what === "string" ? what : what.type;
  return type !== TYPE_STATEMENT && type !== TYPE_WELCOME_SCREEN;
}

export function toStandardDateString(value: any) {
  if (isString(value)) return value.split(/[\/\-.:]/).join("/");
  return value;
}

export interface StringMap<T = any> {
  [key: string]: T;
}

export interface NumberMap<T = any> {
  [key: number]: T;
}

export type Map<K extends string | number, T = any> = K extends number
  ? NumberMap<T>
  : StringMap<T>;

export interface WithID {
  id: string;
}

export interface Keyable<KeyType = string> {
  readonly key: KeyType;
  readonly label?: string;
}
