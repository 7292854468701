import thunkMiddleware from 'redux-thunk';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import userReducer from 'src/store/user/reducers';

const rootReducer = combineReducers({
  user: userReducer
});

export type AppState = ReturnType<typeof rootReducer>


export default createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware)
);
