import React, { memo } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useColorStyle } from './utils';
import NewLineToLineBreakText from './NewLineToLineBreakText';
import { useFormTheme } from './theme';

interface FieldDescriptionProps {
  enabled?: boolean
  text?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontWeight: 'unset',
    fontSize: '20px',
    lineHeight: '28px',
    opacity: 0.7,
    marginTop: theme.spacing(2),
  },
}));

const FieldDescription: React.FC<FieldDescriptionProps> = props => {
  const classes = useStyles();
  const { titleColor } = useFormTheme();
  const colorStyle = useColorStyle(titleColor);
  const {enabled, text} = props;

  if (!enabled || !text)
    return null;

  return (
    <div className={classes.root} style={colorStyle}>
      <NewLineToLineBreakText value={text} />
    </div>
  );
};

export default memo(FieldDescription);
