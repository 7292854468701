import React from "react";

interface NewLineToLineBreakTextProps {
  value: string;
}

const NewLineToLineBreakText: React.FC<NewLineToLineBreakTextProps> = (
  props
) => {
  const chunks = props.value.trim().split("\n");
  const lastIndex = chunks.length - 1;

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.match(urlRegex, function (url) {
      return `${url}`;
    });
  }

  return (
    <>
      {chunks.map((item, index) => (
        <span key={index}>
          {item.replace(urlify(item), "")}{" "}
          {
            <a target='_blank' rel='noreferrer' href={urlify(item)}>
              {" "}
              {urlify(item)}
            </a>
          }
          {index !== lastIndex && <br />}
        </span>
      ))}
    </>
  );
};

export default React.memo(NewLineToLineBreakText);
