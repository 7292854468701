import React from 'react';
import grey from '@material-ui/core/colors/grey';
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@material-ui/core/IconButton/IconButton';
import { Theme, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { TooltipProps } from '@material-ui/core/Tooltip';

interface IconButtonProps extends MuiIconButtonProps {
  icon: React.ReactNode
  label?: string
  isActive?: boolean
  activeLabel?: string
  activeColor?: string
  borderRadius?: string | number
  tooltipPlacement?: TooltipProps['placement']
}

const IconButton: React.FC<IconButtonProps> = props => {
  const {
    icon,
    label,
    isActive,
    activeLabel,
    activeColor,
    borderRadius,
    tooltipPlacement,
    disabled,
    style,
    ...others
  } = props;

  const title = isActive && activeLabel ? activeLabel : label;
  const color = isActive && activeColor ? activeColor : grey[500];
  const theme = useTheme<Theme>();

  const Icon = (
    <MuiIconButton
      style={{
        color,
        borderRadius: borderRadius || theme.spacing(1),
        ...style,
      }}
      aria-label={title}
      disabled={disabled}
      {...others}  >
      {icon}
    </MuiIconButton>
  );

  if (title && !disabled) {
    return (
      <Tooltip
        placement={tooltipPlacement}
        interactive
        title={title}>
        {Icon}
      </Tooltip>
    );
  }

  return Icon;
};

IconButton.defaultProps = {
  size: 'small',
  tooltipPlacement: 'bottom-end',
};

export default IconButton;
