import React from 'react';
import { Link, makeStyles, Theme } from '@material-ui/core';
import { useColorStyle } from './utils';
import { useFormTheme } from './theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(12),
  },
  poweredBy: {
    fontSize: 12,
  },
  logo: {
    maxHeight: 100,
    maxWidth: '100%',
  },
}));

const CreateYourOwnFormButton: React.FC = () => {
  const classes = useStyles();
  const { buttonColor } = useFormTheme();
  const textColorStyle = useColorStyle(buttonColor);

  return (
    <div className={classes.root}>
      <div className={classes.poweredBy} style={textColorStyle}>
        Proudly Powered By
      </div>
      <Link target="_blank" href="https://jibusasa.com" underline="none">
        <img alt="JibuSasa Forms" src="/logo.png" className={classes.logo}/>
      </Link>
    </div>
  );
};

export default React.memo(CreateYourOwnFormButton);
