import { memo } from 'react';

export interface IPlainInputProps<Value> {
  value: Value
  onChange: (value: Value) => void
  disabled?: boolean
}

export const TypedReactMemo: <T>(c: T) => T = memo;

export type PlainFn = () => void;

