import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import FullHeightGrid from "../ui/FullHeightGrid";
import Cell from "../ui/Cell";
import { LookAndFeel } from "../types/theme";
import {
  JUMP_TARGET_DISQUALIFY,
  JUMP_TARGET_END,
  StringMap,
  TYPE_DATE,
  TYPE_NUMBER,
  TYPE_STATEMENT,
  TYPE_WELCOME_SCREEN,
} from "src/global";
import SubmitPage from "./SubmitPage";
import { FormResponseData, GeoCoordinates } from "../types/response";
import { computeFieldPosition } from "../helpers";
import RefreshAndCloseButtons from "./RefreshAndCloseButtons";
import ThemeContainer from "./ThemeContainer";
import ThankYou from "./ThankYou";
import { LinearProgress } from "@material-ui/core";
import ScreeningFail from "./ScreeningFail";
import { useSnackbar } from "notistack";
import PopupLoading from "../ui/PopupLoading";
import { uniqueRulesEnabled } from "./bounce";
import getAnswerableCollector from "./getAnswerableCollector";
import { arrayReduceToObject, identityFn } from "src/global/arrayUtils";
import moment from "moment";
import GeoLocation from "./GeoLocation";
import { Field, Form } from "../types/form";
import AnswersResource from "../api/AnswersResource";
import { FormThemeProvider } from "./theme";
import { AnswerState, BaseCollectorProps } from "./types";
import StatementCollector from "./StatementCollector";
import Welcome from "./Welcome";
import SwiperCore, { Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./index.css";
import "swiper/swiper-bundle.min.css";
import SwiperClass from "swiper/types/swiper-class";
import useMapChangeMaker from "../hooks/useMapChangeMaker";

SwiperCore.use([Virtual]);

export interface ResponseCollectorProps {
  theme: LookAndFeel;
  form: Form;
  onSubmit: (data: FormResponseData) => Promise<void>;

  activeField?: string;
  onActiveFieldChange?: (id: string) => void;

  defaultData?: FormResponseData;
  closable?: boolean;
  dummy?: boolean;
}

const VIEW_NORMAL = "normal";
const VIEW_THANK_YOU = "thank_you";
const VIEW_SCREENING_FAIL = "screening_fail";

const ACT_SCREEN = "screening";
const ACT_SUBMIT = "submitting";
const ACT_IDLE = "idle";

const ResponseCollector: FC<ResponseCollectorProps> = (props) => {
  const {
    defaultData,
    onActiveFieldChange,
    onSubmit,
    activeField,
    theme,
    form,
    closable,
    dummy,
    form: { fields, uniqueResponseRules, id: formId },
  } = props;

  /*******************************************
   * STATE
   ******************************************/
  const [startedAt, setStartedAt] = useState(Date.now);
  const [answers, setAnswers] = useState<StringMap<AnswerState<any>>>({});
  const [jumpSteps, setJumpSteps] = useState<StringMap<string>>({});
  const [view, setView] = useState(VIEW_NORMAL);
  const [action, setAction] = useState(ACT_IDLE);

  /*******************************************
   * GEO LOCATION
   ******************************************/
  const locationRef = useRef<GeoCoordinates>();
  const setLocation = useCallback((newLocation?: GeoCoordinates) => {
    locationRef.current = newLocation;
  }, []);

  /*******************************************
   * SWIPER
   ******************************************/
  const swiperRef = useRef<SwiperClass>();
  const ignoreSlideChangeEventRef = useRef(false);

  const handleSwiperInit = (swiper: SwiperClass) => {
    swiperRef.current = swiper;
  };

  const slideNext = useCallback(() => {
    swiperRef.current && swiperRef.current.slideNext();
  }, []);

  const slidePrev = useCallback(() => {
    swiperRef.current && swiperRef.current.slidePrev();
  }, []);

  const slideTo = useCallback(
    (index: number) => {
      if (swiperRef.current && index !== swiperRef.current.activeIndex) {
        ignoreSlideChangeEventRef.current = true;
        setTimeout(() => {
          if (swiperRef.current) swiperRef.current.slideTo(index, 300, false);

          setTimeout(() => {
            ignoreSlideChangeEventRef.current = false;
          }, 300);
        }, 1);
      }

      if (onActiveFieldChange && index > -1 && index < fields.length)
        onActiveFieldChange(fields[index].id);
    },
    [onActiveFieldChange, fields]
  );

  const handleSlideChange = (swiper: SwiperClass) => {
    if (!ignoreSlideChangeEventRef.current) {
      const { activeIndex, previousIndex } = swiper;
      if (activeIndex > previousIndex) handleSlideNextRequest(previousIndex);
      else handleSlidePrevRequest(previousIndex);
    }
  };

  const handleTransitionEnd = () => {
    ignoreSlideChangeEventRef.current = false;
  };

  // @ts-ignore
  if (swiperRef.current && !swiperRef.current.destroyed) {
    swiperRef.current.off("slideChange");
    swiperRef.current.off("transitionEnd");
    swiperRef.current.on("slideChange", handleSlideChange);
    swiperRef.current.on("transitionEnd", handleTransitionEnd);
  }

  /*******************************************
   * SWIPER EVENT LISTENERS
   ******************************************/
  const { enqueueSnackbar } = useSnackbar();

  const handleSlideNextRequest = (prevIndex: number) => {
    const { id: prevFieldId } = fields[prevIndex];
    const answerState = answers[prevFieldId] || {};

    /***** Check for errors ****/
    if (answerState.error) {
      slideTo(prevIndex);
      makeAnswerChangeHandler(prevFieldId)((state) => ({
        ...state,
        showError: true,
      }));
      return;
    }

    /***** Process logic jump ****/
    let target = answerState.jumpTo;

    // Disqualify and fail fast
    if (target === JUMP_TARGET_DISQUALIFY) {
      setView(VIEW_SCREENING_FAIL);
      return;
    }

    // Target index or the end
    let index = -1;
    if (target === JUMP_TARGET_END) index = fields.length;
    else if (target) index = fields.findIndex((field) => field.id === target);

    // No valid target found? Simply jump to next
    if (index == -1) {
      index = prevIndex + 1;
      target = index >= fields.length ? JUMP_TARGET_END : fields[index].id;
    }

    slideTo(index);
    setJumpSteps((prevState) => ({
      ...prevState,
      [target!]: prevFieldId,
    }));

    /***** Unique validation ********/
    if (uniqueRulesEnabled(prevFieldId, uniqueResponseRules)) {
      setAction(ACT_SCREEN);
      AnswersResource.isUniqueAnswer({
        answer: answerState.answer,
        formId: formId,
        fieldId: prevFieldId,
      })
        .then((unique) => {
          !unique && setView(VIEW_SCREENING_FAIL);
        })
        .catch(() => {
          slideTo(prevIndex);
          enqueueSnackbar(
            "Screening failed. Please check your internet connection",
            { variant: "error" }
          );
        })
        .finally(() => setAction(ACT_IDLE));
    }
  };

  const handleSlidePrevRequest = (targetIndex: number) => {
    const targetId =
      targetIndex >= fields.length ? JUMP_TARGET_END : fields[targetIndex].id;
    const cameFrom = jumpSteps[targetId];
    const index = fields.findIndex((field) => field.id === cameFrom);
    slideTo(Math.max(0, index));
  };

  /****************************************
   * REFRESH | INITIALIZATION
   ***************************************/
  // Start with different values to force running on initialization
  const prevRefreshTokenRef = useRef(-1);
  const [refreshToken, setRefreshToken] = useState(0);

  const handleRefresh = useCallback(
    () => setRefreshToken((value) => value + 1),
    []
  );

  useEffect(() => {
    if (prevRefreshTokenRef.current !== refreshToken) {
      prevRefreshTokenRef.current = refreshToken;

      setLocation(undefined);
      setStartedAt(Date.now());
      setJumpSteps({});
      setView(VIEW_NORMAL);
      setAction(ACT_IDLE);
      setAnswers(
        (() => {
          const answers: StringMap<AnswerState<any>> = {};

          if (defaultData && defaultData.answers) {
            Object.keys(defaultData.answers).forEach((key) => {
              let answer = defaultData.answers[key];

              // Handle date fields
              const field = fields.find((field) => field.id === key);
              if (field && field.type === TYPE_DATE && answer)
                answer = moment(answer).format(field.dateFormat);

              answers[key] = { answer };
            });
          }

          return answers;
        })()
      );
    }
  }, [refreshToken, defaultData, fields]);

  /****************************************
   * EXTERNAL SWIPER SYNCHRONIZATION
   ***************************************/
  // Takes care of instances where the user is editing the form
  // In these cases, the collector is merely a preview of live changes
  useEffect(() => {
    const _index = fields.findIndex((x) => x.id === activeField);
    const swipeIndex = Math.max(0, _index);
    const swiper = swiperRef.current;
    if (swiper && swipeIndex !== swiper.activeIndex) {
      slideTo(swipeIndex);
      setAction(ACT_IDLE);
    }
  }, [fields, activeField, slideTo]);

  const handleSubmit = () => {
    const touchedFields: string[] = [];
    let key = JUMP_TARGET_END;
    while (key) {
      touchedFields.push(key);
      key = jumpSteps[key];
    }

    // Extract answers only {fieldId => answer} mapping
    const answersOnly = arrayReduceToObject(
      Object.keys(answers).filter((key) => touchedFields.includes(key)),
      identityFn,
      (key) => {
        const { answer } = answers[key];

        const field = fields.find((field) => field.id === key);
        if (!field) return answer;

        switch (field.type) {
          // Handle date fields
          case TYPE_DATE:
            const date = moment.utc(answer, field.dateFormat).toISOString();
            return {
              type: TYPE_DATE,
              value: date,
            };

          // Handle number fields
          case TYPE_NUMBER:
            const num = parseFloat(answer);
            return isNaN(num) ? null : num;

          // Handle others
          default:
            return answer;
        }
      }
    );

    setAction(ACT_SUBMIT);
    onSubmit({
      form: formId,
      startedAt,
      answers: answersOnly,
      jumpPath: touchedFields.reverse(),
      finishedAt: Date.now(),
      completionTime: (Date.now() - startedAt) / 1000,
      location: locationRef.current,
    })
      .then(() => setView(VIEW_THANK_YOU))
      .catch(console.log)
      .finally(() => setAction(ACT_IDLE));
  };

  const makeAnswerChangeHandler = useMapChangeMaker(setAnswers);

  const renderField = (field: Field) => {
    const { id, type } = field;

    const baseProps: BaseCollectorProps = {
      onGoNext: slideNext,
      onGoBack: slidePrev,
      field: field,
      position: computeFieldPosition(fields, field),
    };

    switch (type) {
      case TYPE_STATEMENT:
        return <StatementCollector {...baseProps} key={id} />;

      case TYPE_WELCOME_SCREEN:
        return <Welcome {...baseProps} key={id} />;

      default:
        const Collector = getAnswerableCollector(type);
        return (
          <Collector
            {...baseProps}
            value={answers[id]}
            onChange={makeAnswerChangeHandler(id)}
            key={id}
          />
        );
    }
  };

  const progress = swiperRef.current ? swiperRef.current.progress : 1;

  return (
    <FormThemeProvider value={theme}>
      <ThemeContainer heightUnit="%" key={refreshToken}>
        <FullHeightGrid columns="1fr" rows="auto auto 1fr" gap={0}>
          <Cell>
            {form.progressBar.enabled && (
              <LinearProgress variant="determinate" value={100 * progress} />
            )}
          </Cell>

          <Cell>
            <RefreshAndCloseButtons
              onRefresh={handleRefresh}
              disabled={action === ACT_SUBMIT}
              closable={closable}
            />
          </Cell>

          <Cell>
            {view === VIEW_SCREENING_FAIL && <ScreeningFail />}

            {view === VIEW_THANK_YOU && (
              <ThankYou message={form.thankYouMessage} />
            )}

            {view === VIEW_NORMAL && (
              <Swiper
                onSwiper={handleSwiperInit}
                virtual
                simulateTouch={false}
                style={{ height: "100%" }}
              >
                {fields.map((field, index) => (
                  <SwiperSlide key={field.id} virtualIndex={index}>
                    {renderField(field)}
                  </SwiperSlide>
                ))}
                <SwiperSlide virtualIndex={fields.length}>
                  <SubmitPage
                    onSubmit={handleSubmit}
                    submitting={action === ACT_SUBMIT}
                  />
                </SwiperSlide>
              </Swiper>
            )}
          </Cell>
        </FullHeightGrid>
      </ThemeContainer>

      {!dummy && (
        <GeoLocation
          onChange={setLocation}
          required={form.geoLocation}
          forced={
            swiperRef.current && swiperRef.current.activeIndex === fields.length
          }
        />
      )}

      <PopupLoading open={action === ACT_SCREEN} />
    </FormThemeProvider>
  );
};

export default ResponseCollector;
