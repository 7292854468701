import React from 'react';
import Scrollable from '../ui/Scrollable';
import { makeStyles, Theme } from '@material-ui/core';
import HorizontalPositionedContainer from './HorizontalPositionedContainer';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    verticalAlign: 'top',
    display: 'inline-block',
    position: 'relative',
    top: 0,
    width: '100%',
    height: '100%',
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  innerContainer: {
    position: 'relative',
  },
}));

const FieldContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const { children, className, ...others } = props;
  const classes = useStyles();

  return (
    <section {...others} className={clsx(className, classes.root)}>
      <Scrollable>
        <HorizontalPositionedContainer className={classes.container}>
          <div className={classes.innerContainer}>
            {children}
          </div>
        </HorizontalPositionedContainer>
      </Scrollable>
    </section>
  );
};

export default FieldContainer;
