import produce, { Draft } from 'immer';
import { UserActionTypes, UserState, SET_USER } from 'src/store/user/types';

const initialState: UserState = {
  user: undefined,
};

export default function userReducer(state = initialState, action: UserActionTypes): UserState {
  return produce(state, (draft: Draft<UserState>) => {
    if (action.type === SET_USER) {
      draft.user = action.user;
    }
  });
};
