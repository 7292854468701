import React, { useCallback } from 'react';
import IconButton from '../ui/IconButton';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import Grid from '../ui/Grid';
import Cell from '../ui/Cell';
import { makeStyles, Theme } from '@material-ui/core';
import { useFormTheme } from './theme';

interface RefreshAndCloseButtonProps {
  onRefresh: React.MouseEventHandler
  disabled?: boolean
  closable?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2, 6, 0),
  },
  refreshContainer: {
    textAlign: "right"
  }
}));

const RefreshAndCloseButtons: React.FC<RefreshAndCloseButtonProps> = props => {
  const classes = useStyles();
  const {buttonColor} = useFormTheme();
  const {closable, disabled, onRefresh} = props;

  const handleClose: React.MouseEventHandler = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    window.parent.postMessage('jibusasa-close', '*');
  }, []);

  return (
    <Grid columns="1fr 1fr" className={classes.root}>
      <Cell middle>
        <div>
          {closable && (
            <IconButton
              size="medium"
              disabled={disabled}
              label="Close"
              activeColor={buttonColor}
              isActive
              icon={<CloseIcon/>}
              onClick={handleClose}/>
          )}
        </div>
      </Cell>
      <Cell middle className={classes.refreshContainer}>
        <div>
          <IconButton
            size="medium"
            disabled={disabled}
            label="Clear answers and restart"
            activeColor={buttonColor}
            isActive
            icon={<RefreshIcon/>}
            onClick={onRefresh}/>
        </div>
      </Cell>
    </Grid>
  );
};

export default React.memo(RefreshAndCloseButtons);
