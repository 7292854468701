import { createContext, useContext } from 'react';
import { LookAndFeel } from '../types/theme';

const FormThemeContext = createContext<LookAndFeel | null>(null);

export const FormThemeProvider = FormThemeContext.Provider;

export function useFormTheme() {
  const theme = useContext(FormThemeContext);
  if (!theme)
    throw new Error('Can not use useFormTheme outside FormThemeContext.Provider');
  return theme;
}
