import axios, { AxiosResponse } from "axios";
import { parseAxiosError } from "./api/errors";
import urlJoin from "url-join";
import { StringMap } from "src/global";
import isArray from "lodash/isArray";

const API_VERSION = "v2";

const BASE_URL = urlJoin(
  process.env.NODE_ENV === "production"
    ? // 'https://api.jibusasa.com' : 'http://192.168.100.92:3030',
      "https://api.jibusasa.com"
    : "https://api.jibusasa.com",
  API_VERSION
);

const execute = async <T = any>(
  operation: Promise<AxiosResponse<T>>
): Promise<T> => {
  try {
    const { data } = await operation;
    console.log(data);
    return data;
  } catch (e) {
    // console.log("Axios Error:", e.message);
    throw parseAxiosError(e as any);
  }
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {},
  withCredentials: true,
});

export default class Api {
  static BASE_URL = BASE_URL;

  static join = urlJoin;

  static replace(path: string | string[], params: StringMap) {
    path = isArray(path) ? urlJoin(path) : path;
    return path.replace(/:(\w+)/g, (_, key) => {
      if (params.hasOwnProperty(key)) return params[key];
      throw Error(`Missing param key "${key}" for "${path}"`);
    });
  }

  static get<T = void>(path: string, params: Record<string, any> = {}) {
    return execute<T>(axiosInstance.get(path, { params }));
  }

  static delete<T = void>(path: string, params: Record<string, any> = {}) {
    return execute<T>(axiosInstance.delete(path, { params }));
  }

  static post<T>(path: string, data: Record<string, any>) {
    return execute<T>(axiosInstance.post(path, data));
  }

  static put<T>(path: string, data: Record<string, any>, headers?: object) {
    return execute<T>(axiosInstance.put(path, data, { headers }));
  }

  static patch<T = void>(path: string, data: Record<string, any>) {
    return execute<T>(axiosInstance.patch(path, data));
  }
}
