import {
  GROUP_CHOICE,
  GROUP_DATE,
  GROUP_NULL,
  GROUP_NUMERIC,
  GROUP_TEXT,
  StringMap,
  toStandardDateString,
} from "./index";
import moment from "moment";

export const INPUT_TYPE_TEXT = "text";
export const INPUT_TYPE_NUMBER = "number";
export const INPUT_TYPE_DATE = "date";
export const INPUT_TYPE_LIST = "list";

export interface CheckParam<Answer = any, Value = Answer> {
  answer: Answer;
  value: Value;
}

export interface Operator {
  text: string;
  check: (param: CheckParam) => boolean;
  group: string;
  input?: string;
  error?: string;
}

export const dt = (value: any) =>
  moment.utc(toStandardDateString(value)).toDate().getTime();

const OPERATORS: StringMap<Operator> = {
  // Null
  null: {
    group: GROUP_NULL,
    text: "is blank",
    check: () => true,
    error: "",
  },
  not_null: {
    group: GROUP_NULL,
    text: "is not blank",
    check: ({ answer }: CheckParam<number>) => answer === 0 || !!answer,
    error: "provided",
  },
  // Text
  exact: {
    group: GROUP_TEXT,
    text: "equals",
    input: INPUT_TYPE_TEXT,
    check: ({ answer, value }: CheckParam<string>) => answer === value,
    error: "be exactly '{}'",
  },
  contains: {
    group: GROUP_TEXT,
    text: "contains",
    input: INPUT_TYPE_TEXT,
    check: ({ answer, value }: CheckParam<string>) => answer.includes(value),
    error: "contain '{}'",
  },
  startsWith: {
    group: GROUP_TEXT,
    text: "starts with",
    input: INPUT_TYPE_TEXT,
    check: ({ answer, value }: CheckParam<string>) => answer.startsWith(value),
    error: "start with '{}'",
  },
  endsWith: {
    group: GROUP_TEXT,
    text: "ends with",
    input: INPUT_TYPE_TEXT,
    check: ({ answer, value }: CheckParam<string>) => answer.endsWith(value),
    error: "end with '{}'",
  },
  notContains: {
    group: GROUP_TEXT,
    text: "does not contain",
    input: INPUT_TYPE_TEXT,
    check: ({ answer, value }: CheckParam<string>) => !answer.includes(value),
    error: "not contain '{}'",
  },
  longThan: {
    group: GROUP_TEXT,
    text: "is long than",
    input: INPUT_TYPE_NUMBER,
    check: ({ answer, value }: CheckParam<string, number>) =>
      answer.length >= value,
    error: "have at most '{}' characters",
  },
  shortThan: {
    group: GROUP_TEXT,
    text: "is short than",
    input: INPUT_TYPE_NUMBER,
    check: ({ answer, value }: CheckParam<string, number>) =>
      answer.length <= value,
    error: "have at least '{}' characters",
  },

  // Numeric
  eq: {
    group: GROUP_NUMERIC,
    text: "is equal to",
    input: INPUT_TYPE_NUMBER,
    check: ({ answer, value }: CheckParam<number>) => answer === value,
    error: "equal to '{}'",
  },
  neq: {
    group: GROUP_NUMERIC,
    text: "is not equal to",
    input: INPUT_TYPE_NUMBER,
    check: ({ answer, value }: CheckParam<number>) => answer !== value,
    error: "not equal to '{}'",
  },
  lt: {
    group: GROUP_NUMERIC,
    text: "is less than",
    input: INPUT_TYPE_NUMBER,
    check: ({ answer, value }: CheckParam<number>) => answer < value,
    error: "less than '{}'",
  },
  lte: {
    group: GROUP_NUMERIC,
    text: "is equal or less than",
    input: INPUT_TYPE_NUMBER,
    check: ({ answer, value }: CheckParam<number>) => answer <= value,
    error: "less than or equal to '{}'",
  },
  gt: {
    group: GROUP_NUMERIC,
    text: "is greater than",
    input: INPUT_TYPE_NUMBER,
    check: ({ answer, value }: CheckParam<number>) => answer > value,
    error: "greater than '{}'",
  },
  gte: {
    group: GROUP_NUMERIC,
    text: "is equal or greater than",
    input: INPUT_TYPE_NUMBER,
    check: ({ answer, value }: CheckParam<number>) => answer >= value,
    error: "greater than or equal to '{}'",
  },
  whole: {
    group: GROUP_NUMERIC,
    text: "is whole number",
    check: ({ answer }: CheckParam<number>) => answer % 1 === 0,
    error: "whole number",
  },

  // Dates
  dateEq: {
    group: GROUP_DATE,
    text: "is on",
    input: INPUT_TYPE_DATE,
    check: ({ answer, value }: CheckParam<number>) => dt(answer) === dt(value),
    error: "on '{}'",
  },
  dateNeq: {
    group: GROUP_DATE,
    text: "is not on",
    input: INPUT_TYPE_DATE,
    check: ({ answer, value }: CheckParam<number>) => dt(answer) !== dt(value),
    error: "not on '{}'",
  },
  dateLt: {
    group: GROUP_DATE,
    text: "is before",
    input: INPUT_TYPE_DATE,
    check: ({ answer, value }: CheckParam<number>) => dt(answer) < dt(value),
    error: "before '{}'",
  },
  dateLte: {
    group: GROUP_DATE,
    text: "is before or on",
    input: INPUT_TYPE_DATE,
    check: ({ answer, value }: CheckParam<number>) => dt(answer) <= dt(value),
    error: "before or on '{}'",
  },
  dateGt: {
    group: GROUP_DATE,
    text: "is after",
    input: INPUT_TYPE_DATE,
    check: ({ answer, value }: CheckParam<number>) => dt(answer) > dt(value),
    error: "after '{}'",
  },
  dateGte: {
    group: GROUP_DATE,
    text: "is after or on",
    input: INPUT_TYPE_DATE,
    check: ({ answer, value }: CheckParam<number>) => dt(answer) >= dt(value),
    error: "after or on '{}'",
  },

  // Choice
  selected: {
    group: GROUP_CHOICE,
    text: "is",
    input: INPUT_TYPE_LIST,
    check: ({ answer, value }: CheckParam<string[], string>) =>
      answer.includes(value),
    error: "include '{}'",
  },
  not_selected: {
    group: GROUP_CHOICE,
    text: "is not",
    input: INPUT_TYPE_LIST,
    check: ({ answer, value }: CheckParam<string[], string>) =>
      !answer.includes(value),
    error: "not include '{}'",
  },
};

export default OPERATORS;
