import { useMemo } from "react";
import { Field } from "../types/form";
import { wellDefinedChoiceLabels } from "../helpers";
import { OTHER_TEXT } from "src/global";
import { pick } from "src/global/objectUtils";

function shuffleInPlace(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export const ChoicesSliceKeys: Array<
  keyof Pick<
    Field,
    "choices" | "alphabeticalOrder" | "randomize" | "otherChoiceEnabled"
  >
> = ["choices", "alphabeticalOrder", "randomize", "otherChoiceEnabled"];

export const withChoicesSlicer = (field: Field) =>
  pick(field, ChoicesSliceKeys);

export type WithChoicesSlice = ReturnType<typeof withChoicesSlicer>;

export const extractProcessedChoices = (field: Field) => {
  const { choices, alphabeticalOrder, randomize, otherChoiceEnabled } = field;
  let processedChoices = wellDefinedChoiceLabels(choices);
  if (alphabeticalOrder) processedChoices.sort();
  if (randomize) shuffleInPlace(processedChoices);
  if (otherChoiceEnabled) processedChoices.push(OTHER_TEXT);
  return processedChoices.map((choice) => choice.trim());
};

export default function useChoices(slice: WithChoicesSlice) {
  const { choices, alphabeticalOrder, randomize, otherChoiceEnabled } = slice;

  return useMemo(() => {
    let processedChoices = wellDefinedChoiceLabels(choices);
    if (alphabeticalOrder) processedChoices.sort();
    if (randomize) shuffleInPlace(processedChoices);
    if (otherChoiceEnabled) processedChoices.push(OTHER_TEXT);
    return processedChoices.map((choice) => choice.trim());
  }, [choices, alphabeticalOrder, randomize, otherChoiceEnabled]);
}
