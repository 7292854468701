import React from 'react';
import { useTheme } from '@material-ui/core';
import { useIsSmallScreen } from '../helpers';

export interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  gap?: number
  rows?: string
  xsRows?: string
  columns?: string
  xsColumns?: string
  areas?: string
}

const Grid: React.FC<GridProps> = props => {
  const { rows, xsRows, columns, xsColumns, gap, areas, style, ...divProps } = props;
  const isSmall = useIsSmallScreen();
  const theme = useTheme();

  const gridGap = theme.spacing(gap!);
  const gridAutoRows = `minmax(${theme.spacing(5)}, auto)`;
  const gridColumns = isSmall ? xsColumns || columns : columns;
  const gridRows = isSmall ? xsRows || rows : rows;

  const gridStyle: React.CSSProperties = React.useMemo(
    () => ({
      display: 'grid',
      gridGap: gridGap,
      gridAutoFlow: 'row',
      gridAutoRows: gridAutoRows,
      gridTemplateColumns: gridColumns,
      gridTemplateRows: gridRows,
      gridTemplateAreas: areas,
      ...style,
    }),
    [gridColumns, gridRows, gridGap, gridAutoRows, areas, style]);

  return (
    <div
      {...divProps}
      style={gridStyle}/>
  );
};

Grid.defaultProps = {
  gap: 2,
  columns: '1fr',
  rows: '1fr',
};

export default Grid;
