import Person from '@material-ui/icons/PersonOutline';
import PersonFilled from '@material-ui/icons/Person';

import Star from '@material-ui/icons/StarBorderRounded';
import StarFilled from '@material-ui/icons/Star';

import Heart from '@material-ui/icons/FavoriteBorderOutlined';
import HeartFilled from '@material-ui/icons/Favorite';

import Circle from '@material-ui/icons/RadioButtonUncheckedOutlined';
import CircleFilled from '@material-ui/icons/RadioButtonChecked';

import ThumbUp from "@material-ui/icons/ThumbUpAltOutlined";
import ThumbUpFilled from "@material-ui/icons/ThumbUpAlt";

import ThumbDown from "@material-ui/icons/ThumbDownAltOutlined";
import ThumbDownFilled from "@material-ui/icons/ThumbDownAlt";

import Flag from "@material-ui/icons/FlagOutlined";
import FlagFilled from "@material-ui/icons/Flag";

import Done from "@material-ui/icons/CheckCircleOutlineRounded";
import DoneFilled from "@material-ui/icons/CheckCircleRounded";

const ratingShapeItems = [
    {id: 'star', Icon: Star, IconFilled: StarFilled, text: "Stars"},
    {id: 'heart', Icon: Heart, IconFilled: HeartFilled, text: "Hearts"},
    {id: 'user', Icon: Person, IconFilled: PersonFilled, text: "Users"},
    {id: 'circle', Icon: Circle, IconFilled: CircleFilled, text: "Circles"},
    {id: 'thumbUp', Icon: ThumbUp, IconFilled: ThumbUpFilled, text: "Thumbs Up"},
    {id: 'thumbDown', Icon: ThumbDown, IconFilled: ThumbDownFilled, text: "Thumbs Down"},
    {id: 'flag', Icon: Flag, IconFilled: FlagFilled, text: "Flags"},
    {id: 'tick', Icon: Done, IconFilled: DoneFilled, text: "Ticks"}
];

export default ratingShapeItems;
