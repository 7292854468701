import React from 'react';
import FieldContainer from './FieldContainer';
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';
import ThemedButton from './ThemedButton';
import DoneIcon from '@material-ui/icons/DoneOutlined';
import { useFormTheme } from './theme';
import { PlainFn } from '../types';

interface SubmitPageProps {
  onSubmit: PlainFn
  submitting?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(6),
  },
  submitText: {
    marginRight: theme.spacing(3),
  },
}));

const SubmitPage: React.FC<SubmitPageProps> = props => {
  const classes = useStyles();
  const { buttonColor } = useFormTheme();
  const { onSubmit, submitting } = props;

  return (
    <FieldContainer>
      <div className={classes.root}>
        <ThemedButton
          color={buttonColor}
          onClick={onSubmit}
          disabled={submitting}>
            <span className={classes.submitText}>
              SUBMIT
            </span>
          {submitting && <CircularProgress size={15}/>}
          {!submitting && <DoneIcon/>}
        </ThemedButton>
      </div>
    </FieldContainer>
  );
};

export default React.memo(SubmitPage);
