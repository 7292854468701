import { Person } from 'src/types/user';

export const SET_USER = 'SET_USER';

export interface UserState {
  user?: Person
}

interface SetUser {
  type: typeof SET_USER
  user?: Person
}

export type UserActionTypes = SetUser;
