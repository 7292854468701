import React, { useCallback } from 'react';
import { ButtonProps } from '@material-ui/core/Button';
import { Button, Theme as MuiTheme } from '@material-ui/core';
import * as colorManipulator from '@material-ui/core/styles/colorManipulator';
import { ThemeProvider } from '@material-ui/styles';
import { foregroundColor } from '../helpers';

type ThemedButtonProps = Omit<ButtonProps, 'color'> & { color: string }

const ThemedButton: React.FC<ThemedButtonProps> = props => {
  const { color, ...others } = props;

  const createMuiTheme = useCallback(
    (outerTheme: MuiTheme) => ({
      ...outerTheme,
      palette: {
        ...outerTheme.palette,
        primary: {
          ...outerTheme.palette.primary,
          main: color,
          light: colorManipulator.lighten(color, 0.4),
          dark: colorManipulator.darken(color, 0.6),
          contrastText: foregroundColor(color),
        },
      },
    }),
    [color],
  );

  return (
    <ThemeProvider theme={createMuiTheme}>
      <Button
        color="primary"
        variant="contained"
        {...others} />
    </ThemeProvider>
  );
};

export default React.memo(ThemedButton);
