import { ComponentType } from "react";
import AnswerInputHOC, { CollectorProps } from "./AnswerInputHOC";
import TextCollector from "./TextCollector";
import LongTextCollector from "./LongTextCollector";
import MultiChoiceCollector from "./MultiChoiceCollector";
import RatingCollector from "./RatingCollector";
import NumberCollector from "./NumberCollector";
import OpinionScaleCollector from "./OpinionScaleCollector";
import EmailCollector from "./EmailCollector";
import DropdownCollector from "./DropdownCollector";
import PhoneNumberCollector from "./PhoneNumberCollector";
import URLCollector from "./URLCollector";
import DateCollector from "./DateCollector";
import {
  StringMap,
  TYPE_DATE,
  TYPE_DROPDOWN,
  TYPE_EMAIL,
  TYPE_LONG_TEXT,
  TYPE_MULTI_CHOICE,
  TYPE_NUMBER,
  TYPE_OPINION_SCALE,
  TYPE_PHONE_NUMBER,
  TYPE_RATING,
  TYPE_TEXT,
  TYPE_URL,
} from "src/global";

const AnswerableCollectorMap: StringMap<ComponentType<CollectorProps<any>>> = {
  [TYPE_TEXT]: AnswerInputHOC(TextCollector),
  [TYPE_LONG_TEXT]: AnswerInputHOC(LongTextCollector),
  [TYPE_MULTI_CHOICE]: AnswerInputHOC(MultiChoiceCollector),
  [TYPE_RATING]: AnswerInputHOC(RatingCollector),
  [TYPE_NUMBER]: AnswerInputHOC(NumberCollector),
  [TYPE_OPINION_SCALE]: AnswerInputHOC(OpinionScaleCollector),
  [TYPE_EMAIL]: AnswerInputHOC(EmailCollector),
  [TYPE_DROPDOWN]: AnswerInputHOC(DropdownCollector),
  [TYPE_PHONE_NUMBER]: AnswerInputHOC(PhoneNumberCollector),
  [TYPE_URL]: AnswerInputHOC(URLCollector),
  [TYPE_DATE]: AnswerInputHOC(DateCollector),
};

export default function getAnswerableCollector(type: string) {
  if (!AnswerableCollectorMap.hasOwnProperty(type))
    throw Error(`Unknown collector type : ${type}`);
  return AnswerableCollectorMap[type];
}
