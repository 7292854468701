import React, { CSSProperties } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';

interface SpacerProps {
  size?: number
  vertical?: boolean
}

const Spacer: React.FC<SpacerProps> = ({ size, vertical }) => {
  const theme = useTheme();
  const spacing = theme.spacing(size!);

  const style: CSSProperties = React.useMemo(() => {
    if (vertical)
      return {
        height: spacing,
      };

    return {
      width: spacing,
      display: 'inline-block',
    };
  }, [spacing, vertical]);

  return <div style={style}>&nbsp;</div>;
};

Spacer.defaultProps = {
  size: 2,
};

export default React.memo(Spacer);
