import { AxiosError } from "axios";
import { E_UNKNOWN } from "src/global/errors";

export interface AttributeValidationError {
  param: string;
  msg: string;
}

interface IApiError {
  status?: number;
  code?: string;
  message?: string;
  errors?: AttributeValidationError[];
}

export class ApiError extends Error {
  public readonly status: number;
  public readonly code: string;
  public readonly message: string;
  public readonly msg: string;
  public readonly errors: AttributeValidationError[];

  constructor(e: IApiError) {
    super();
    this.status = e.status || 500;
    this.code = e.code || E_UNKNOWN;
    this.message = this.msg =
      e.message || "An error occurred while communicating to the server";
    this.errors = e.errors || [];
  }

  isValidation = () => this.status === 400;
}

export function parseAxiosError(error: AxiosError): ApiError {
  if (error.response) {
    const { status, data } = error.response;
    return new ApiError({
      status,
      code: data.code,
      message: data.message,
      errors: data.errors,
    });
  } else {
    return new ApiError({});
  }
}
