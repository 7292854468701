import { StringMap, WithID } from './index';

export function arrayReduceToObject<T, V>(
  items: Readonly<T[]>,
  key: (item: T) => string,
  value: (item: T, index: number) => V,
  initialValue: StringMap<V> = {},
  isValid: (value: V) => boolean = () => true
): StringMap<V> {
  return items.reduce(
    (doc, item, index) => {
      const computedValue = value(item,index);
      if (isValid(computedValue))
        return ({ ...doc, [key(item)]: computedValue });
      return doc;
    },
    initialValue,
  );
}

function makeArrayNumericReducer(
  reducer: (aggregate: number, value: number, size: number) => number,
  initializer: (firstValue: number) => number
) {
  return <T>(
    items: T[],
    value: (item: T) => number,
    initialValue?: number
  ) => {
    if (items.length === 0)
      return initialValue === undefined ? 0 : initialValue;

    if (initialValue === undefined)
      initialValue = initializer(value(items[0]));

    const size = items.length;

    return items.reduce(
      (aggregate, item) => reducer(aggregate, value(item), size),
      initialValue
    );
  }
}

export const arrayReduceAddition = makeArrayNumericReducer(
  (a, b) => a + b,
  () => 0
);

export const arrayReduceMean = makeArrayNumericReducer(
  (a, b, size) => a + b / size,
  () => 0
);

export const arrayReduceMin = makeArrayNumericReducer(
  (a, b) => Math.min(a, b),
  firstValue => firstValue
);

export const arrayReduceMax = makeArrayNumericReducer(
  (a, b) => Math.max(a, b),
  firstValue => firstValue
);

export function identityFn<T>(x: T) {
  return x;
}

export function getId(x: WithID) {
  return x.id;
}
