import { Theme as MuiTheme } from '@material-ui/core';
import { LookAndFeel } from '../types/theme';
import * as colorManipulator from '@material-ui/core/styles/colorManipulator';
import { foregroundColor, isLightColor } from '../helpers';

const fadedShade = (color: string) => isLightColor(color) ?
  colorManipulator.darken(color, 0.4) :
  colorManipulator.lighten(color, 0.4);

export const makeTheme = (muiTheme: MuiTheme, theme: LookAndFeel): MuiTheme => ({
  ...muiTheme,
  typography: {
    ...muiTheme.typography,
    fontFamily: theme.fontFamily,
  },
  palette: {
    ...muiTheme.palette,
    primary: {
      main: theme.answerColor,
      light: colorManipulator.lighten(theme.answerColor, 0.4),
      dark: colorManipulator.darken(theme.answerColor, 0.6),
      contrastText: foregroundColor(theme.answerColor),
    },
  },
  overrides: {
    ...muiTheme.overrides,
    MuiLinearProgress: {
      root: {
        height: muiTheme.spacing(2)
      },
      colorPrimary: {
        backgroundColor: fadedShade(theme.answerColor),
      },
    },
    MuiInputLabel: {
      outlined: {
        color: theme.answerColor,
        fontSize: 30,
        lineHeight: '38px',
        opacity: .6,

        '&$shrink': {
          opacity: 1,
          transform: 'translate(14px, -15px) scale(0.75)',
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        color: theme.answerColor,
        marginTop: muiTheme.spacing(1)
      },
    },
    MuiOutlinedInput: {
      root: {
        color: theme.answerColor,
        fontSize: 30,
        lineHeight: '38px',

        '&:hover > fieldset': {
          borderColor: `${fadedShade(theme.answerColor)} !important`,
        },
      },
      notchedOutline: {
        borderColor: fadedShade(theme.answerColor),
      },
    },
    MuiButton: {
      root: {
        '&.active': {
          borderColor: theme.answerColor,
        },
      },
      outlined: {
        borderWidth: '2px !important',
      },
      label: {
        fontFamily: theme.fontFamily,
        fontSize: '20px',
        lineHeight: '28px',
        textTransform: 'none',
        fontWeight: 400,
      },
    },
    MuiTypography: {
      root: {
        fontFamily: `${theme.fontFamily} !important`,
      },
    },
  },
});
