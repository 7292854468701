import React from "react";
import ShortTextIcon from "@material-ui/icons/ShortText";
import LongTextIcon from "@material-ui/icons/Notes";
import StarIcon from "@material-ui/icons/StarBorder";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import BarChartIcon from "@material-ui/icons/BarChart";
import PlusOneIcon from "@material-ui/icons/Grain";
import LinkIcon from "@material-ui/icons/Link";
import DoneIcon from "@material-ui/icons/DoneAll";
import CaretDownIcon from "@material-ui/icons/KeyboardArrowDown";
import QuoteIcon from "@material-ui/icons/FormatQuote";
import ForumIcon from "@material-ui/icons/Forum";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { Field } from "./types/form";
import {
  GROUP_CHOICE,
  GROUP_DATE,
  GROUP_NULL,
  GROUP_NUMERIC,
  GROUP_TEXT,
  StringMap,
  TYPE_DATE,
  TYPE_DROPDOWN,
  TYPE_EMAIL,
  TYPE_LONG_TEXT,
  TYPE_MULTI_CHOICE,
  TYPE_NUMBER,
  TYPE_OPINION_SCALE,
  TYPE_PHONE_NUMBER,
  TYPE_RATING,
  TYPE_STATEMENT,
  TYPE_TEXT,
  TYPE_URL,
  TYPE_WELCOME_SCREEN,
} from "src/global";
import { green, lime } from "@material-ui/core/colors";

type CanAutoJump = (field: Field) => boolean;

type FieldKeyArray = Array<keyof Field>;

interface FieldMeta {
  group: string;
  properties: string[];
  color: string;
  Icon: React.ComponentType<SvgIconProps>;
  name: string;
  reconcile?: (field: Field) => Field;
  disableValidation?: boolean;
  canAutoJump?: boolean | CanAutoJump;
  defaultValue?: any;
  constraints?: StringMap;
}

const mergeProps = (props: FieldKeyArray, remove: FieldKeyArray = []) => {
  const all: FieldKeyArray = [
    "type",
    "required",
    "descriptionEnabled",
    ...props,
  ];
  return all.filter((prop) => remove.indexOf(prop) < 0);
};

const mergeStatementProps = (props: FieldKeyArray) =>
  mergeProps(props, ["required"]);

export const FieldMetas: StringMap<FieldMeta> = {
  [TYPE_WELCOME_SCREEN]: {
    group: GROUP_NULL,
    properties: mergeStatementProps(["leftAlign", "image", "buttonText"]),
    color: lime[600],
    Icon: ForumIcon,
    name: "Welcome screen",
    reconcile(field: Field) {
      field.buttonText = field.buttonText || "Continue";
      return field;
    },
    disableValidation: true,
  },
  [TYPE_MULTI_CHOICE]: {
    group: GROUP_CHOICE,
    properties: mergeProps([
      "multiSelectionEnabled",
      "randomize",
      "otherChoiceEnabled",
    ]),
    color: "#4fa9b3",
    Icon: DoneIcon,
    name: "Multiple choice",
    canAutoJump: (field) => !field.multiSelectionEnabled,
    defaultValue: [],
  },
  [TYPE_OPINION_SCALE]: {
    group: GROUP_NUMERIC,
    properties: mergeProps(["steps", "labels"]),
    color: "#74a4bf",
    Icon: BarChartIcon,
    name: "Opinion scale",
    reconcile(field: Field) {
      field.steps = field.steps || 10;
      return field;
    },
    canAutoJump: true,
    defaultValue: null,
  },
  [TYPE_RATING]: {
    group: GROUP_NUMERIC,
    properties: mergeProps(["steps", "shape"]),
    color: "#a086c4",
    Icon: StarIcon,
    name: "Rating",
    reconcile(field: Field) {
      field.steps = field.steps || 5;
      field.shape = field.shape || "star";
      return field;
    },
    canAutoJump: true,
    defaultValue: null,
  },
  [TYPE_NUMBER]: {
    group: GROUP_NUMERIC,
    properties: mergeProps([]),
    color: "#e04f78",
    Icon: PlusOneIcon,
    name: "Number",
    defaultValue: null,
  },
  [TYPE_TEXT]: {
    group: GROUP_TEXT,
    properties: mergeProps([]),
    color: "#ffba49",
    Icon: ShortTextIcon,
    name: "Short text",
    defaultValue: "",
  },
  [TYPE_LONG_TEXT]: {
    group: GROUP_TEXT,
    properties: mergeProps([]),
    color: "#E26d5a",
    Icon: LongTextIcon,
    name: "Long text",
    defaultValue: "",
  },
  [TYPE_PHONE_NUMBER]: {
    group: GROUP_TEXT,
    properties: mergeProps([
      /*'defaultCountry'*/
    ]),
    color: "#3FC46A",
    Icon: PhoneIcon,
    name: "Phone number",
    defaultValue: "",
  },
  [TYPE_EMAIL]: {
    group: GROUP_TEXT,
    properties: mergeProps([]),
    color: "#3a7685",
    Icon: EmailIcon,
    name: "Email",
    defaultValue: "",
  },
  [TYPE_DATE]: {
    group: GROUP_DATE,
    properties: mergeProps(["dateFormat"]),
    color: "#E65100",
    Icon: CalendarIcon,
    name: "Date",
    reconcile(field: Field) {
      field.dateFormat = "DD/MM/YYYY";
      return field;
    },
    defaultValue: "",
  },
  [TYPE_URL]: {
    group: GROUP_TEXT,
    properties: mergeProps([]),
    color: "#bac26c",
    Icon: LinkIcon,
    name: "Website / URL",
    defaultValue: "",
  },
  [TYPE_DROPDOWN]: {
    group: GROUP_CHOICE,
    properties: mergeProps(["randomize", "otherChoiceEnabled"]),
    color: "#58457a",
    Icon: CaretDownIcon,
    name: "Dropdown",
    reconcile(field: Field) {
      field.multiSelectionEnabled = false;
      return field;
    },
    canAutoJump: true,
    defaultValue: "",
  },
  [TYPE_STATEMENT]: {
    group: TYPE_STATEMENT,
    properties: mergeStatementProps(["buttonText"]),
    color: green[400],
    Icon: QuoteIcon,
    name: "Statement",
    reconcile(field: Field) {
      field.buttonText = field.buttonText || "Continue";
      return field;
    },
    disableValidation: true,
  },
};

export const getFieldMeta = (type: string): FieldMeta => {
  const meta = FieldMetas[type];
  if (!meta) throw Error(`Unknown field type: ${type}`);
  return meta;
};

export const reconcileField = (field: Field): Field => {
  const { reconcile } = FieldMetas[field.type];
  return reconcile ? reconcile(field) : field;
};
