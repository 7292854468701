import React from 'react';
import PhoneNumberTextField from '../ui/PhoneNumberTextField';
import AnswerInput from './AnswerInput';

export default AnswerInput({
  defaultValue: '' as string,
  keys: ['defaultCountry'],
  isEmpty: value => !value || value === '+',
})(props => {
  const { onChange, value, defaultCountry } = props;

  return (
    <PhoneNumberTextField
      defaultCountry={defaultCountry || 'ke'}
      variant="outlined"
      fullWidth
      value={value}
      onChange={onChange}/>
  );
});
