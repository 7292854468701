import React, { Fragment } from "react";
import store from "src/store";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import BaseApp from "src/shared/BaseApp";
import HomePage from "src/pages/HomePage";
import CollectorPage from "src/pages/CollectorPage";
import { useServiceWorker } from "src/useServiceWorker";
import UpdateNotification from "src/ui/UpdateNotification";

const App: React.FC = () => {
  const serviceWorker = useServiceWorker();
  const needUpdate =
    serviceWorker.assetsUpdateReady && !serviceWorker.assetsCached;

  return (
    <BaseApp store={store}>
      <Fragment>
        <Router>
          <Switch>
            <Route path='/:id' component={CollectorPage} />
            <Route path='/' component={HomePage} exact />
          </Switch>
        </Router>
        {needUpdate && (
          <UpdateNotification onClick={serviceWorker.updateAssets} />
        )}
      </Fragment>
    </BaseApp>
  );
};

export default App;
