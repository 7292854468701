import React, { useCallback, useEffect } from 'react';
import { Theme as MuiTheme } from '@material-ui/core';
import { makeTheme } from './makeTheme';
import { ThemeProvider } from '@material-ui/styles';
import ThemedBackgroundBox from '../ui/ThemedBackgroundBox';
import { loadFont } from '../helpers';
import { useFormTheme } from './theme';

interface ThemeContainerProps {
  heightUnit: '%' | 'vh'
}

const ThemeContainer: React.FC<ThemeContainerProps> = props => {
  const { heightUnit, children } = props;
  const theme = useFormTheme();

  useEffect(() => loadFont(theme), [theme]);

  const createMuiTheme = useCallback(
    (ot: MuiTheme) => makeTheme(ot, theme),
    [theme]);

  return (
    <ThemeProvider theme={createMuiTheme}>
      <ThemedBackgroundBox
        theme={theme}
        height={`100${heightUnit}`}
        fontFamily={theme.fontFamily}>
        {children}
      </ThemedBackgroundBox>
    </ThemeProvider>
  );
};

export default ThemeContainer;
