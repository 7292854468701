import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(8, 0, 4),
  },
}));

const AnswerInputContainer: FC = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.children}
    </div>
  );
};

export default AnswerInputContainer;
