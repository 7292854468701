import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import ChoiceItem from "./ChoiceItem";
import useCachedCallback from "../hooks/useCachedCallback";
import useChoices, { ChoicesSliceKeys } from "../hooks/useChoices";
import AnswerInput from "./AnswerInput";

const useStyles = makeStyles({
  root: {
    display: "inline-block",
    minWidth: 200,
  },
});

export default AnswerInput({
  defaultValue: [] as string[],
  keys: [...ChoicesSliceKeys, "multiSelectionEnabled"],
})((props) => {
  const classes = useStyles();
  const { multiSelectionEnabled, value, onChange, ...choicesSlice } = props;
  const choices = useChoices(choicesSlice);

  const handleClick = useCallback(
    (label: string) => {
      if (value.includes(label))
        return onChange(value.filter((x) => x !== label));

      if (multiSelectionEnabled) return onChange([...value, label]);

      return onChange([label]);
    },
    [value, multiSelectionEnabled, onChange]
  );

  const getClickHandler = useCachedCallback(handleClick);

  return (
    <div className={classes.root}>
      {choices.map((label) => (
        <ChoiceItem
          key={label}
          label={label}
          active={value.includes(label)}
          multi={multiSelectionEnabled}
          onClick={getClickHandler(label)}
        />
      ))}
    </div>
  );
});
