import React, { memo } from 'react';
import FieldContainer from './FieldContainer';
import { Theme } from '@material-ui/core';
import FieldTitle from './FieldTitle';
import ThemedButton from './ThemedButton';
import FieldDescription from './FieldDescription';
import { makeStyles } from '@material-ui/styles';
import { useFormTheme } from './theme';
import { BaseCollectorProps } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'center',
  },

  leftAlign: {
    textAlign: 'left',
  },

  image: {
    maxWidth: '100%',
    maxHeight: 200,
    marginBottom: theme.spacing(6),
  },

  buttonContainer: {
    marginTop: theme.spacing(6),
  },
  buttonText: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
}));

const Welcome: React.FC<BaseCollectorProps> = props => {
  const classes = useStyles();
  const { buttonColor } = useFormTheme();
  const { field, onGoNext } = props;

  return (
    <FieldContainer>
      <div className={classes.root}>
        {field.image && (
          <img src={field.image} alt="Welcome" className={classes.image}/>
        )}

        <div className={field.leftAlign ? classes.leftAlign : undefined}>
          <FieldTitle
            title={field.title}/>

          <FieldDescription
            enabled={field.descriptionEnabled}
            text={field.description}/>
        </div>

        <div className={classes.buttonContainer}>
          <ThemedButton color={buttonColor} onClick={onGoNext}>
            <span className={classes.buttonText}>
              {field.buttonText || 'OK'}
            </span>
          </ThemedButton>
        </div>
      </div>
    </FieldContainer>
  );
};

export default memo(Welcome);
