// import "'./wdyr";
import React from 'react';
import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import MuiTheme from './theme';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@material-ui/core';
import { configureDefaults } from '../helpers';
import { Store } from 'redux';

configureDefaults();

const generateClassName = createGenerateClassName({
  productionPrefix: 'S',
  disableGlobal: true
});

interface BaseAppProps {
  store: Store
  children?: React.ReactElement<any, any>;
}

const BaseApp: React.FC<BaseAppProps> = props => {
  const {children, store} = props;

  return (
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={MuiTheme}>
          <CssBaseline/>
          <SnackbarProvider maxSnack={5}>
            {children}
          </SnackbarProvider>
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  );
};

export default BaseApp;
