import React, { ChangeEventHandler, useCallback } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import AnswerInput from './AnswerInput';

const NumericInputProps: TextFieldProps['inputProps'] = {
  pattern: '[0-9.]*',
  inputMode: 'numeric',
};

export default AnswerInput({
  defaultValue: null as number | null,
  keys: [],
  constraints: {
    numericality: true,
  },
  cast(value: any) {
    if (typeof value === 'string')
      value = +value;

    if (isNaN(value) || typeof value !== 'number')
      return null;

    return value;
  },
})(props => {
  const { onChange, value } = props;

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => onChange(e.target.value as unknown as number),
    [onChange]);

  return (
    <TextField
      inputProps={NumericInputProps}
      fullWidth
      multiline={false}
      placeholder="Type your answer here..."
      defaultValue={value === null ? '' : value}
      onChange={handleChange}
      variant="outlined"
    />
  );
});
