import React from "react";
import { Box, Button, Link } from "@material-ui/core";
import { getClientOrigin } from "src/shared/helpers";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
});

const HomePage: React.FC = () => {
  const classes = useStyles();

  return (
    <Box maxWidth={700} mx='auto' my={10} px={3} textAlign='center'>
      <img
        alt='Jibusasa Online Forms'
        src='/logo.png'
        style={{ maxWidth: "100%", maxHeight: 250 }}
      />

      <Box mt={3}>
        <Link href={getClientOrigin()} classes={classes}>
          <Button variant='outlined' size='small' color='primary'>
            <Box
              component='span'
              fontSize={24}
              lineHeight='24px'
              fontWeight={500}
            >
              Create Your Own Form
            </Box>
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default HomePage;
