import React, { memo } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useColorStyle } from './utils';
import NewLineToLineBreakText from './NewLineToLineBreakText';
import { useFormTheme } from './theme';

interface FieldTitleProps {
  title?: string
  required?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    lineHeight: '32px',
    fontWeight: 'unset',
    fontSize: '24px',
  },
  star: {
    marginRight: theme.spacing(1),
  },
}));

const FieldTitle: React.FC<FieldTitleProps> = props => {
  const classes = useStyles();
  const { titleColor } = useFormTheme();
  const colorStyle = useColorStyle(titleColor);
  const { title, required } = props;

  return (
    <Typography style={colorStyle} className={classes.root}>
      <NewLineToLineBreakText value={title || '...'}/>
      {required && <span className={classes.star}>*</span>}
    </Typography>
  );
};

export default memo(FieldTitle);
