import React from "react";
import deburr from "lodash/deburr";
import Downshift from "downshift";
import TextField from "@material-ui/core/TextField";
import ChoiceItem from "./ChoiceItem";
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, Theme } from "@material-ui/core";
import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input";

interface Suggestion {
  label: string;
}

type Value = string; // | string[]

interface AutoSuggestProps<T extends Value> {
  suggestions: Suggestion[];
  value?: T;
  onChange(value: any): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
  },
  suggestionsContainer: {
    marginTop: theme.spacing(1),
    maxHeight: 250,
    overflowY: "auto",
  },
}));

const AutoSuggest: React.FC<AutoSuggestProps<Value>> = (props) => {
  const classes = useStyles();
  const [hasFocus, setFocus] = React.useState<boolean>(false);

  const { suggestions } = props;

  const getSuggestions = React.useCallback(
    (value: string, showEmpty?: boolean) => {
      const inputValue = deburr(value.trim()).toLowerCase();
      const inputLength = inputValue.length;
      const matches = ({ label }: Suggestion) =>
        label.slice(0, inputLength).toLowerCase() === inputValue;
      return inputLength === 0 && !showEmpty ? [] : suggestions.filter(matches);
    },
    [suggestions]
  );

  return (
    <div>
      <Downshift onChange={props.onChange} selectedItem={props.value}>
        {({
          getRootProps,
          getInputProps,
          getItemProps,
          getMenuProps,
          inputValue,
          isOpen,
          selectedItem,
        }) => (
          <div {...getRootProps()} className={classes.container}>
            <TextField
              fullWidth={true}
              multiline={false}
              variant="outlined"
              InputProps={
                getInputProps({
                  placeholder: "Select an option",
                  onFocus: () => setFocus(true),
                  onBlur: () => setFocus(false),
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDown />
                    </InputAdornment>
                  ),
                }) as Partial<StandardInputProps>
              }
            />

            <div {...getMenuProps()}>
              {(isOpen || hasFocus) && (
                <div className={classes.suggestionsContainer}>
                  {getSuggestions(inputValue!, true).map((suggestion) => {
                    const isSelected =
                      (selectedItem || "").indexOf(suggestion.label) > -1;

                    return (
                      <ChoiceItem
                        {...getItemProps({ item: suggestion.label })}
                        key={suggestion.label}
                        label={suggestion.label}
                        active={isSelected}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </Downshift>
    </div>
  );
};

export default React.memo(AutoSuggest);
